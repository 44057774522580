
.sec-title .h2{ font-size: 5.2rem; margin-bottom: 0; font-weight: bold; }
.sec-title h4{font-size: 2.8rem;color: var(--greenColor);font-weight: 400;margin: 3rem 0;}
.what-sec-text{ max-width: 102.6rem; margin:  auto; font-size: 3.2rem; }



@media (max-width:1199px) {
    .sec-title .h2 {font-size: 4.2rem;}
}

@media (max-width:767px) {
    .sec-title .h2 {font-size: 3.2rem;}
    br {display: none;}
}