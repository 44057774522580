/****** Common Css Start ******/

html {
  font-size: 62.5%;
  scroll-behavior: auto !important;
}

@font-face {
  font-family: "Colfax";
  src: url("./Assets/Fonts/Colfax-Bold.woff2") format("woff2"),
    url("./Assets/Fonts/Colfax-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url("./Assets/Fonts/Colfax-Light.woff2") format("woff2"),
    url("./Assets/Fonts/Colfax-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url("./Assets/Fonts/Colfax-Black.woff2") format("woff2"),
    url("./Assets/Fonts/Colfax-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url("./Assets/Fonts/Colfax-Medium.woff2") format("woff2"),
    url("./Assets/Fonts/Colfax-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url("./Assets/Fonts/Colfax-Thin.woff2") format("woff2"),
    url("./Assets/Fonts/Colfax-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url("./Assets/Fonts/Colfax-Regular.woff2") format("woff2"),
    url("./Assets/Fonts/Colfax-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  overflow-x: hidden;
  font-weight: normal;
  font-size: initial;
  font-family: "Colfax";
}

:root {
  --darkText: #232221;
  --bannerBg: #fce8d1;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --greenColor: #0b6623;
  --LightGreenColor: #4eb349;
  --lightBg: #fcfcfc;
  --TextColor: #4a4745;
  --ThemeColorRed: #db4348;
  --ThemeColorYellow: #f5bf15;
}

img {
  max-width: 100%;
}

a,
button,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

button:hover,
button:focus,
input:hover,
input:focus,
textarea:hover,
textarea:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.badge {
  color: var(--whiteColor);
  padding: 0.5rem 1rem;
  font-weight: 500;
  min-width: 4rem;
  text-align: center;
}

.center-clip {
  margin-top: -1rem;
}

.pad-t-80 {
  padding-top: 8rem;
}

.pad-b-80 {
  padding-bottom: 8rem;
}

.pad-t-50 {
  padding-top: 5rem;
}

.pad-b-50 {
  padding-bottom: 5rem;
}

.pad-t-30 {
  padding-top: 3rem;
}

.pad-b-30 {
  padding-bottom: 3rem;
}

.row {
  margin: 0 -15px;
}

.row div[class*="col"] {
  padding: 0 15px;
}

.tooltip {
  font-size: 14px;
}

.step-navigation {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
}

.step-navigation .btn+.btn {
  margin-left: 1rem;
}

.v-sub {
  vertical-align: sub;
}

.white-icon {
  filter: brightness(0) invert(1);
}

th[align="center"] {
  text-align: center;
}

.alert-danger {
  color: #020202;
  background-color: #ffe7ea;
  border-color: #ffbfc2;
}

.alert {
  font-size: 14px;
  border-radius: 1rem;
}

.breadcrumb {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-radius: 0.5rem;
}

.breadcrumb2 {
  background: transparent;
  padding: 0;
  margin-bottom: 1rem;
}

.breadcrumb-item.active {
  color: #adadad;
}

.breadcrumb a {
  color: var(--blackColor);
  font-weight: 500;
}

.table> :not(:first-child) {
  border-top: 2px solid #eef0f8;
}

.center-align-table {
  text-align: center;
}

td[align="left"],
th[align="left"] {
  text-align: left;
}

/****** Common Css End ******/

.congrats-bx {
  text-align: center;
  color: var(--TextColor);
}

.congrats-bx h2 {
  font-size: 4rem;
  font-weight: 700;
}

.congrats-bx img {
  margin: 2rem 0;
}

.congrats-bx p {
  margin-bottom: 2rem;
  font-size: 2rem;
  margin: 0;
}

.modal-content .btn-close {
  position: absolute;
  right: -3px;
  top: -3px;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--bannerBg);
  opacity: 1;
  border-radius: 50%;
  background-size: 13px;
}

.clnicaltrial-detail-ftr {
  margin-top: 3rem;
  display: flex;
  align-items: center;
}

.clnicaltrial-detail-ftr .btn {
  flex: 1 1 auto;
}

.clnicaltrial-detail-ftr>*+* {
  margin-left: 1rem;
}

.confirmation-popup .modal-body {
  padding: 4rem 2rem;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat");

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.notifications-table tr {
  vertical-align: initial;
}

.text-elipis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  border: 0;
  line-height: 2.3;
}

.border-left-cu {
  border-left: 1px solid #eef0f8;
}

td.max-width {
  max-width: 300px;
  word-break: break-all;
}

.go-content-plan {
  text-align: center;
}

.go-content-plan button {
  width: 100%;
  min-width: 15rem !important;
  min-height: 50px;
}

.go-content-plan button .white-icon {
  display: none;
}

iframe[style*="fixed"] {
  display: none;
}

.full-btn .btn {
  flex: 1 1 auto;
}

/* table */
.table {
  background: var(--whiteColor);
}

.table td,
.table th {
  font-size: 1.6rem;
  font-weight: 500;
}

.table-hover tr:hover {
  background: #f3f3f3 !important;
}

.table thead th {
  vertical-align: bottom;
  white-space: nowrap;
}

.table thead tr {
  background: #ededed;
}

.table td,
.table th {
  padding: 1.5rem 1rem;
  vertical-align: middle;
  text-align: center;
}

.table .thead-dark th {
  color: #949494;
  background-color: var(--LightSectionColor);
  border-color: var(--LightSectionColor);
  font-size: 1.6rem;
  font-family: "FilsonProBook";
  font-weight: normal;
  text-transform: uppercase;
}

.table .thead-grey th {
  color: var(--BlackColor);
  background-color: #d4d4d4;
}

.table-bordered td,
.table-bordered th {
  border: none;
  border-bottom: 1px solid #e6e6e6;
}

/* table */

.optionListContainer .highlight {
  border-radius: 0;
  margin-top: 0;
}

.multiSelectContainer li:hover {
  background: var(--lightBg) !important;
  color: #000000 !important;
}

.optionListContainer li.selected {
  background: var(--greenColor) !important;
  color: #ffffff !important;
  margin-bottom: 4px;
}

.multiSelectContainer .chip {
  background: var(--greenColor);
}

.material-icons {
  font-size: 20px;
}

.bx-list>ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 17px;
}

.bx-list>ul>li {
  margin-bottom: 25px;
  width: 50%;
  font-weight: 600;
}

.bx-list>ul>li>ul>li>ul>li {
  font-weight: 400;
  margin: 25px 0 25px 0;
  display: list-item;
  width: fit-content;
  cursor: pointer;
}

.bx-list>ul>li>ul>li>span {
  margin: 25px 0 25px 0;
  padding: 0 30px 0 30px;
}

.bx-list>ul>li>ul>li {
  margin: 25px 0 25px 0;
}

.invoice-tab table th,
.invoice-tab table td {
  white-space: initial;
}

/* Date Picker */
.react-datepicker__input-container input[type="text"] {
  min-height: 46px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 10px;
  width: 100%;
}

.custom-select.form-control {
  padding: 0 20px 0px 10px;
  min-height: 46px;
  height: auto;
  width: 100%;
}

.custom-select.form-control {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #e1e5eb;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.7rem !important;
  line-height: 2.7rem !important;
}

.react-datepicker {
  font-size: 1.5rem !important;
}

.footer-modal-btn .btn {
  margin: 0.25rem;
}

.dashboard-bx-custom h5 {
  font-size: 2.4rem;
  margin: 0 0 15px !important;
}

.pagination .page-link {
  padding: 10px 20px;
  color: #0b6623;
}

.page-item.active .page-link {
  background-color: #0b6623;
  border-color: #0b6623;
}

.page-link:focus {
  box-shadow: none;
}

.pagination {
  margin: 15px auto;
}

.pagination .page-link:hover {
  color: #ffffff;
  background-color: #0b6623;
  border-color: #0b6623;
}

/*Grade css*/
.grade-list {
  list-style: none;
  margin: 0;
  /* display: flex;
  flex-wrap: wrap; */
  box-shadow: 0 0 15px #ccc;
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;
}

/* .list-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; */
/* } */

.list-ul li {
  width: calc(100% / 4 - 10px);
  padding: 10px;
  /* background-color: #CCC; */
  border-radius: 5px;
  border: 2px solid #0b6623;
  margin-right: 10px;
  margin-bottom: 10px;
}

.list-ul-design {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.list-ul-design li {
  width: calc(100% / 6 - 10px);
  padding: 10px;
  /* background-color: #CCC; */
  border-radius: 5px;
  border: 2px solid #0b6623;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grade-list h2 {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
}

.grade-list li button {
  padding: 0;
  width: 100%;
  min-width: unset;
}

.grade-list li img {
  width: 100%;
}

.subjects-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.subjects-list li {
  width: calc(100% / 3 - 10px);
  margin-right: 15px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-bottom: 15px;
}

.subjects-list li:nth-child(3n + 3) {
  margin-right: 0;
}

.subjects-list li .bx-subject {
  position: relative;
}

.subjects-list li .bx-subject span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 60px 15px 25px;
  font-size: 16px;
  color: #fff;
}

.modal {
  z-index: 999999;
}

.sidebar-iframe {
  background: #fff;
  padding: 25px;
  height: 100%;
}

.sidebar-iframe .logo {
  margin-bottom: 15px;
  display: block;
}

.sidebar-iframe ul {
  margin: 0;
  height: 100%;
  list-style: none;
  padding: 15px 0 0 0;
  border-top: 2px solid #000;
}

.slide-bar-design-iframe select {
  border: 1px solid #ccc;
  width: 100%;
  padding: 8px 23px 8px 8px;
}

.slide-bar-design-iframe select:focus,
.slide-bar-design-iframe select:focus-visible {
  border: 1px solid #ccc;
  outline: none !important;
}

.sidebar-iframe>ul>li {
  margin-bottom: 10px;
  font-weight: 400;
  padding: 8px;
}

.no-recod {
  border: 2px solid #ff0000;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
  background-color: #fff8db;
}

.sidebar-iframe>ul>li.active {
  background-color: #4c9900;
  color: #fff;
}

.togg-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.togg-btn>select {
  display: block;
  padding: 0 5px;
  position: absolute;
  right: -5px;
  border: 0;
  background-color: transparent;
  z-index: 0;
  width: 18px;
  text-align: center;
}

.togg-btn>select:focus {
  border: 0;
  outline: none;
  /* box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%); */
}

.togg-btn>select:focus {
  border: 0;
}

.togg-btn .dropdown-bx {
  position: absolute;
  top: 15px;
  left: 22px;
  min-width: 180px;
  background-color: #fff;
  box-shadow: 0 0 15px #ccc;
  display: flex;
  flex-direction: column;
  z-index: 9;
  border-radius: 5px;
  display: none;
}

.togg-btn:focus button:focus+.dropdown-bx {
  display: flex;
}

.togg-btn .dropdown-bx>span {
  padding: 5px 0;
}

@media (max-width: 1366px) {
  .sidebar-iframe {
    padding: 10px;
  }
}

@media (max-width: 1199px) {
  .list-ul-design li {
    width: calc(100% / 3 - 10px);
  }
}

@media (max-width: 991px) {
  .header-free-trial .btn-dark {
    margin-bottom: 2rem;
  }

  .subjects-list li {
    padding: 0;
  }

  .subjects-list li .bx-subject span {
    padding: 15px 40px 15px 25px;
  }

  .sidebar-iframe {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidebar-iframe ul {
    padding: 0;
    border-top: 0;
  }

}

@media (max-width: 767px) {
  .bx-list>ul>li {
    width: 100%;
  }

  .payment-method {
    width: 100% !important;
  }

  .subjects-list li {
    padding: 0;
    width: calc(100% / 2 - 10px);
  }

  .subjects-list li:nth-child(3n + 3) {
    margin-right: 15px;
  }

  .subjects-list li:nth-child(2n + 2) {
    margin-right: 0;
  }

  .grade-list h2 {
    font-size: 20px;
  }

  .list-ul-design li {
    width: calc(100% / 4 - 10px);
  }
}

@media (max-width: 575px) {
  .modal-dialog {
    margin: 1.5rem;
  }

  .subjects-list li .bx-subject span {
    font-size: 14px;
  }

  .list-ul-design li {
    width: calc(100% / 2 - 10px);
  }

  .sidebar-iframe {
    display: flex;
  }

  .sidebar-iframe ul {
    padding: 0;
    border-top: 0;
  }

  .sidebar-iframe .logo img {
    width: auto;
  }

  .iframe-sec iframe {
    min-height: 200px;
  }
}

/* 
.selected {
  background: #0b6623 !important;
} */