@import url(https://fonts.googleapis.com/css?family=Montserrat);
/****** Common Css Start ******/

html {
  font-size: 62.5%;
  scroll-behavior: auto !important;
}

@font-face {
  font-family: "Colfax";
  src: url(/static/media/Colfax-Bold.7133822e.woff2) format("woff2"),
    url(/static/media/Colfax-Bold.3295509b.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url(/static/media/Colfax-Light.b69f7d7d.woff2) format("woff2"),
    url(/static/media/Colfax-Light.37cdc2b1.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url(/static/media/Colfax-Black.d44128c2.woff2) format("woff2"),
    url(/static/media/Colfax-Black.19f16673.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url(/static/media/Colfax-Medium.630ac2f1.woff2) format("woff2"),
    url(/static/media/Colfax-Medium.0c46dd88.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url(/static/media/Colfax-Thin.8669afb3.woff2) format("woff2"),
    url(/static/media/Colfax-Thin.eb560e1c.woff) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Colfax";
  src: url(/static/media/Colfax-Regular.9e9d7787.woff2) format("woff2"),
    url(/static/media/Colfax-Regular.137c48ce.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  overflow-x: hidden;
  font-weight: normal;
  font-size: initial;
  font-family: "Colfax";
}

:root {
  --darkText: #232221;
  --bannerBg: #fce8d1;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --greenColor: #0b6623;
  --LightGreenColor: #4eb349;
  --lightBg: #fcfcfc;
  --TextColor: #4a4745;
  --ThemeColorRed: #db4348;
  --ThemeColorYellow: #f5bf15;
}

img {
  max-width: 100%;
}

a,
button,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

button:hover,
button:focus,
input:hover,
input:focus,
textarea:hover,
textarea:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.badge {
  color: #ffffff;
  color: var(--whiteColor);
  padding: 0.5rem 1rem;
  font-weight: 500;
  min-width: 4rem;
  text-align: center;
}

.center-clip {
  margin-top: -1rem;
}

.pad-t-80 {
  padding-top: 8rem;
}

.pad-b-80 {
  padding-bottom: 8rem;
}

.pad-t-50 {
  padding-top: 5rem;
}

.pad-b-50 {
  padding-bottom: 5rem;
}

.pad-t-30 {
  padding-top: 3rem;
}

.pad-b-30 {
  padding-bottom: 3rem;
}

.row {
  margin: 0 -15px;
}

.row div[class*="col"] {
  padding: 0 15px;
}

.tooltip {
  font-size: 14px;
}

.step-navigation {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
}

.step-navigation .btn+.btn {
  margin-left: 1rem;
}

.v-sub {
  vertical-align: sub;
}

.white-icon {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

th[align="center"] {
  text-align: center;
}

.alert-danger {
  color: #020202;
  background-color: #ffe7ea;
  border-color: #ffbfc2;
}

.alert {
  font-size: 14px;
  border-radius: 1rem;
}

.breadcrumb {
  padding: 1rem 2rem;
  background: #f9f9f9;
  border-radius: 0.5rem;
}

.breadcrumb2 {
  background: transparent;
  padding: 0;
  margin-bottom: 1rem;
}

.breadcrumb-item.active {
  color: #adadad;
}

.breadcrumb a {
  color: #000000;
  color: var(--blackColor);
  font-weight: 500;
}

.table> :not(:first-child) {
  border-top: 2px solid #eef0f8;
}

.center-align-table {
  text-align: center;
}

td[align="left"],
th[align="left"] {
  text-align: left;
}

/****** Common Css End ******/

.congrats-bx {
  text-align: center;
  color: #4a4745;
  color: var(--TextColor);
}

.congrats-bx h2 {
  font-size: 4rem;
  font-weight: 700;
}

.congrats-bx img {
  margin: 2rem 0;
}

.congrats-bx p {
  margin-bottom: 2rem;
  font-size: 2rem;
  margin: 0;
}

.modal-content .btn-close {
  position: absolute;
  right: -3px;
  top: -3px;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fce8d1;
  background-color: var(--bannerBg);
  opacity: 1;
  border-radius: 50%;
  background-size: 13px;
}

.clnicaltrial-detail-ftr {
  margin-top: 3rem;
  display: flex;
  align-items: center;
}

.clnicaltrial-detail-ftr .btn {
  flex: 1 1 auto;
}

.clnicaltrial-detail-ftr>*+* {
  margin-left: 1rem;
}

.confirmation-popup .modal-body {
  padding: 4rem 2rem;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.notifications-table tr {
  vertical-align: initial;
}

.text-elipis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  border: 0;
  line-height: 2.3;
}

.border-left-cu {
  border-left: 1px solid #eef0f8;
}

td.max-width {
  max-width: 300px;
  word-break: break-all;
}

.go-content-plan {
  text-align: center;
}

.go-content-plan button {
  width: 100%;
  min-width: 15rem !important;
  min-height: 50px;
}

.go-content-plan button .white-icon {
  display: none;
}

iframe[style*="fixed"] {
  display: none;
}

.full-btn .btn {
  flex: 1 1 auto;
}

/* table */
.table {
  background: #ffffff;
  background: var(--whiteColor);
}

.table td,
.table th {
  font-size: 1.6rem;
  font-weight: 500;
}

.table-hover tr:hover {
  background: #f3f3f3 !important;
}

.table thead th {
  vertical-align: bottom;
  white-space: nowrap;
}

.table thead tr {
  background: #ededed;
}

.table td,
.table th {
  padding: 1.5rem 1rem;
  vertical-align: middle;
  text-align: center;
}

.table .thead-dark th {
  color: #949494;
  background-color: var(--LightSectionColor);
  border-color: var(--LightSectionColor);
  font-size: 1.6rem;
  font-family: "FilsonProBook";
  font-weight: normal;
  text-transform: uppercase;
}

.table .thead-grey th {
  color: var(--BlackColor);
  background-color: #d4d4d4;
}

.table-bordered td,
.table-bordered th {
  border: none;
  border-bottom: 1px solid #e6e6e6;
}

/* table */

.optionListContainer .highlight {
  border-radius: 0;
  margin-top: 0;
}

.multiSelectContainer li:hover {
  background: #fcfcfc !important;
  background: var(--lightBg) !important;
  color: #000000 !important;
}

.optionListContainer li.selected {
  background: #0b6623 !important;
  background: var(--greenColor) !important;
  color: #ffffff !important;
  margin-bottom: 4px;
}

.multiSelectContainer .chip {
  background: #0b6623;
  background: var(--greenColor);
}

.material-icons {
  font-size: 20px;
}

.bx-list>ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 17px;
}

.bx-list>ul>li {
  margin-bottom: 25px;
  width: 50%;
  font-weight: 600;
}

.bx-list>ul>li>ul>li>ul>li {
  font-weight: 400;
  margin: 25px 0 25px 0;
  display: list-item;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.bx-list>ul>li>ul>li>span {
  margin: 25px 0 25px 0;
  padding: 0 30px 0 30px;
}

.bx-list>ul>li>ul>li {
  margin: 25px 0 25px 0;
}

.invoice-tab table th,
.invoice-tab table td {
  white-space: initial;
}

/* Date Picker */
.react-datepicker__input-container input[type="text"] {
  min-height: 46px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 10px;
  width: 100%;
}

.custom-select.form-control {
  padding: 0 20px 0px 10px;
  min-height: 46px;
  height: auto;
  width: 100%;
}

.custom-select.form-control {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #e1e5eb;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.7rem !important;
  line-height: 2.7rem !important;
}

.react-datepicker {
  font-size: 1.5rem !important;
}

.footer-modal-btn .btn {
  margin: 0.25rem;
}

.dashboard-bx-custom h5 {
  font-size: 2.4rem;
  margin: 0 0 15px !important;
}

.pagination .page-link {
  padding: 10px 20px;
  color: #0b6623;
}

.page-item.active .page-link {
  background-color: #0b6623;
  border-color: #0b6623;
}

.page-link:focus {
  box-shadow: none;
}

.pagination {
  margin: 15px auto;
}

.pagination .page-link:hover {
  color: #ffffff;
  background-color: #0b6623;
  border-color: #0b6623;
}

/*Grade css*/
.grade-list {
  list-style: none;
  margin: 0;
  /* display: flex;
  flex-wrap: wrap; */
  box-shadow: 0 0 15px #ccc;
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;
}

/* .list-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; */
/* } */

.list-ul li {
  width: calc(100% / 4 - 10px);
  padding: 10px;
  /* background-color: #CCC; */
  border-radius: 5px;
  border: 2px solid #0b6623;
  margin-right: 10px;
  margin-bottom: 10px;
}

.list-ul-design {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.list-ul-design li {
  width: calc(100% / 6 - 10px);
  padding: 10px;
  /* background-color: #CCC; */
  border-radius: 5px;
  border: 2px solid #0b6623;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grade-list h2 {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
}

.grade-list li button {
  padding: 0;
  width: 100%;
  min-width: unset;
}

.grade-list li img {
  width: 100%;
}

.subjects-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.subjects-list li {
  width: calc(100% / 3 - 10px);
  margin-right: 15px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-bottom: 15px;
}

.subjects-list li:nth-child(3n + 3) {
  margin-right: 0;
}

.subjects-list li .bx-subject {
  position: relative;
}

.subjects-list li .bx-subject span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 60px 15px 25px;
  font-size: 16px;
  color: #fff;
}

.modal {
  z-index: 999999;
}

.sidebar-iframe {
  background: #fff;
  padding: 25px;
  height: 100%;
}

.sidebar-iframe .logo {
  margin-bottom: 15px;
  display: block;
}

.sidebar-iframe ul {
  margin: 0;
  height: 100%;
  list-style: none;
  padding: 15px 0 0 0;
  border-top: 2px solid #000;
}

.slide-bar-design-iframe select {
  border: 1px solid #ccc;
  width: 100%;
  padding: 8px 23px 8px 8px;
}

.slide-bar-design-iframe select:focus,
.slide-bar-design-iframe select:focus-visible {
  border: 1px solid #ccc;
  outline: none !important;
}

.sidebar-iframe>ul>li {
  margin-bottom: 10px;
  font-weight: 400;
  padding: 8px;
}

.no-recod {
  border: 2px solid #ff0000;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
  background-color: #fff8db;
}

.sidebar-iframe>ul>li.active {
  background-color: #4c9900;
  color: #fff;
}

.togg-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.togg-btn>select {
  display: block;
  padding: 0 5px;
  position: absolute;
  right: -5px;
  border: 0;
  background-color: transparent;
  z-index: 0;
  width: 18px;
  text-align: center;
}

.togg-btn>select:focus {
  border: 0;
  outline: none;
  /* box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%); */
}

.togg-btn>select:focus {
  border: 0;
}

.togg-btn .dropdown-bx {
  position: absolute;
  top: 15px;
  left: 22px;
  min-width: 180px;
  background-color: #fff;
  box-shadow: 0 0 15px #ccc;
  display: flex;
  flex-direction: column;
  z-index: 9;
  border-radius: 5px;
  display: none;
}

.togg-btn:focus button:focus+.dropdown-bx {
  display: flex;
}

.togg-btn .dropdown-bx>span {
  padding: 5px 0;
}

@media (max-width: 1366px) {
  .sidebar-iframe {
    padding: 10px;
  }
}

@media (max-width: 1199px) {
  .list-ul-design li {
    width: calc(100% / 3 - 10px);
  }
}

@media (max-width: 991px) {
  .header-free-trial .btn-dark {
    margin-bottom: 2rem;
  }

  .subjects-list li {
    padding: 0;
  }

  .subjects-list li .bx-subject span {
    padding: 15px 40px 15px 25px;
  }

  .sidebar-iframe {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidebar-iframe ul {
    padding: 0;
    border-top: 0;
  }

}

@media (max-width: 767px) {
  .bx-list>ul>li {
    width: 100%;
  }

  .payment-method {
    width: 100% !important;
  }

  .subjects-list li {
    padding: 0;
    width: calc(100% / 2 - 10px);
  }

  .subjects-list li:nth-child(3n + 3) {
    margin-right: 15px;
  }

  .subjects-list li:nth-child(2n + 2) {
    margin-right: 0;
  }

  .grade-list h2 {
    font-size: 20px;
  }

  .list-ul-design li {
    width: calc(100% / 4 - 10px);
  }
}

@media (max-width: 575px) {
  .modal-dialog {
    margin: 1.5rem;
  }

  .subjects-list li .bx-subject span {
    font-size: 14px;
  }

  .list-ul-design li {
    width: calc(100% / 2 - 10px);
  }

  .sidebar-iframe {
    display: flex;
  }

  .sidebar-iframe ul {
    padding: 0;
    border-top: 0;
  }

  .sidebar-iframe .logo img {
    width: auto;
  }

  .iframe-sec iframe {
    min-height: 200px;
  }
}

/* 
.selected {
  background: #0b6623 !important;
} */
@media (min-width: 1400px) {
    .container {
        max-width: 1366px;
    }

    .container-fluid {
        max-width: 1644px;
    }

    .mpc-header {
        height: 80px;
    }
}

@media (max-width: 1700px) {
    html {
        font-size: 58%;
    }

    .container-fluid {
        padding: 0 5rem;
    }

    .banner-bg {
        top: -11rem;
    }

    /* .repeat-white-bx.hover-effect p{display:block !important;} */
    .repeat-white-bx .count-num {
        display: block;
        font-size: 20px;
    }
}

@media (max-width: 1400px) {
    html {
        font-size: 50%;
    }

    .child-image-bx {
        min-height: 500px;
    }

    .mpc-header {
        height: 80px;
    }

    .dashboard-right-content {
        height: calc(100vh - 80px) !important;
    }
}



@media (max-width:1199px) {
    .repeat-white-bx.hover-effect p {
        font-size: 12px;
    }

}

@media (max-width: 991px) {
    video {
        width: 100%;
        height: auto;
        margin: 20px 0 0;
    }

    .pad-b-80 {
        padding-bottom: 4rem;
    }

    .pad-t-80 {
        padding-top: 4rem;
    }

    .why-choose-points li {
        padding-left: 8rem;
    }

    .why-choose-points li::before {
        width: 6rem;
        height: 4.5rem;
        font-size: 3rem;
    }

    .container-fluid {
        padding: 0 2rem;
    }

    .what-sec-text {
        font-size: 2rem;
    }

    .sec-title h4 {
        font-size: 1.8rem;
    }

    .dashboard-right-content {
        height: calc(100vh - 80px) !important;
    }
}
.btn { font-size: 16px; font-weight: 500; padding: 12px 2px; min-width: 17rem; border-radius: 5px; border: 1px solid transparent; }
.btn:hover .white-icon{transition: 0.3s;}
.btn:hover .white-icon{-webkit-filter: none;filter: none;}
.btn-green, button.btn-green:focus, a.btn-green:focus { background: var(--greenColor); color:  var(--whiteColor); }
.btn-green:hover{ background: transparent; border-color: var(--greenColor); color:  var(--greenColor); }
.btn-dark, .btn-dark:focus { background: var(--darkText); color:  var(--whiteColor); }
.btn-dark:hover{ background: transparent; border-color: var(--darkText); color: var(--darkText); }
.btn-red, .btn-red:focus { background: var(--ThemeColorRed); color:  var(--whiteColor); }
.btn-red:hover{ background: transparent; border-color: var(--ThemeColorRed); color: var(--ThemeColorRed); }
.btn-white, .btn-white:focus { background: var(--whiteColor); color:  var(--darkText); }
.btn-white:hover{ background: transparent; border-color: var(--whiteColor); color: var(--whiteColor); }
.btn-purple, .btn-purple:focus { background: var(--ThemeColorYellow); color:  var(--darkText); }

.btn-action{border: none;padding: 0;width: 3.5rem;height: 3.5rem;border-radius: 5px;line-height: 0;display: inline-flex;align-items: center;justify-content: center;}
.btn-action img{width: 1.7rem;-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}

.btn-xs{font-size: 14px;min-width: auto;padding: 7px 20px;}
.btn-sm{font-size: 1.6rem;min-width: auto;padding: 1rem 3rem;}
/* select.btn.btn-green:focus {background-color: transparent; color: #000;border-color: #000;} */
.filter select.btn {
    min-width: 15rem;
    padding: 0;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    box-shadow: 0 0 4px rgb(11 102 35 / 50%);
}
header.site-header{ padding: 1.5rem 0; transition: 0.4s; background: var(--bannerBg); }
.home-page header{ padding: 1.5rem 0 0; }
header.sticky, 
.home-page header.sticky{ position: fixed; z-index: 5; background: var(--bannerBg); width: 100%; left: 0; top: 0; padding: 1rem 0; box-shadow: rgb(0 0 0 / 25%) 0px 0px 5px 0px; }
.msingipack-default-navbar{ flex-wrap: nowrap; align-items: flex-start; }
.sticky .msingipack-default-navbar{ align-items: center; }
.msingipack-rightNav-side .navbar-nav { margin-top: 2rem; flex-direction: row; justify-content: flex-end; align-items: center; position: relative; z-index: 99; }
.sticky .msingipack-rightNav-side .navbar-nav{ margin-top: 0; }
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item) { margin: 0 2.5rem; position: relative; }
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item)>a{ color: var(--darkText); font-size: 16px; font-weight: 500; }
.msingipack-rightNav-side .navbar-nav>li:nth-last-child(2)>a{margin-left: 17rem;}
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item)>a:hover,
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item)>a.active{ color: var(--greenColor); }
.msingipack-rightNav-side .navbar-nav>li.btn-item{ margin-left: 2rem; }
.msingipack-rightNav-side .navbar-nav>li.btn-item .btn{ min-width: 120px; }
.navbar-brand img{ position: relative; z-index: 5; transition: 0.3s; width: 13.4rem; }
.sticky .navbar-brand img{ width: 7rem; }

.dropdown-custom{background: var(--greenColor);border-radius: 1rem;padding: 1rem;position: absolute;top: calc(100% + 1rem); left: 0; min-width: 13rem;box-shadow: 0 0 1rem rgba(0,0,0,0.16);display: none;z-index: 99;}
.dropdown-custom::after{content: "";width: 0; height: 0; border-left: 7px solid transparent; border-right: 7px solid transparent; border-bottom: 7px solid var(--greenColor);bottom: 100%;position: absolute; left: 10%;}
.dropdown-custom a{display: block;color: var(--bannerBg);padding: 5px 10px;border-radius: 4px;white-space: nowrap;font-size: 14px;}
.dropdown-custom a:hover,
.dropdown-custom a.active{background: var(--bannerBg);color: var(--darkText);}
.dropdown-custom a + a{margin-top: 2px;}

.msingipack-rightNav-side .navbar-nav>li.hasDropDown::after{content: "";position: absolute;width: 100%;height: 30px;background: transparent;left: 0;top: 100%;}
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item):hover .dropdown-custom{display: block;}


.toggle-btn.website-toggle {display: none !important;}


@media (max-width:1199px) {
    .msingipack-rightNav-side .navbar-nav>li:nth-last-child(2)>a {margin-left: 0;}
    .msingipack-rightNav-side .navbar-nav>li:not(.btn-item) {margin: 0 2rem;}
}
@media (max-width:991px) {
    .toggle-btn.website-toggle {display: flex !important;}
    .msingipack-rightNav-side .navbar-nav>li:not(.btn-item) {
        margin-bottom: 2rem;
    }
    .msingipack-rightNav-side .navbar-nav>li:nth-last-child(2)>a {margin-bottom: 2rem;}
    .num-of-user label {
        max-width: 26rem;
    }
    .subscription-total {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .num-of-user {
        width: 100%;
        justify-content: space-between;
    }
    .grand-total-bx {
        width: 100%;
        justify-content: space-between;
        margin-top: 2rem;
    }
    .msingipack-default-navbar {
        align-items: center;
    }
    .getInTouch-bx {margin-bottom: 3rem;}
    
}

@media (max-width:460px) {
    .grand-total-bx, .num-of-user {
        flex-direction: column;
    }
    .num-of-user label {max-width: 100%;text-align: center;margin: 0 0 2rem;}
    .subscription-total p {
        margin: 0 0 2rem !important;
    }
}
.modal-title{font-size: 2.5rem;font-weight: 700;}
.modal-content{border: none;border-radius: 1rem;}
.modal-header{padding: 1rem 2rem;}
.modal-body{padding: 2rem;}
.modal-footer{padding: 1rem 2rem;}
.hero-section { padding: 0; background-image: url(/static/media/msingi-hero-bg.41e7171a.svg); background-position: bottom center; background-size: cover; background-repeat: no-repeat; margin-top: -5.5rem; position: relative; z-index: 2; }
.banner-bg{position: absolute;right: 0;top: -13rem;pointer-events: none;}
.banner-btm-bg{position: absolute;left: 0;bottom: 0;width: 100%;pointer-events: none;}
.banner-btm-bg img{width: 100%;}
.hero-heading .h1 { color: var(--darkText); font-size: 6.4rem; font-weight: 500; margin: 0; }
.hero-caption p { font-size: 3rem; color: var(--darkText); margin: 2rem 0; }
.hero-img{ position: relative; }
.hero-wave-clip { position: absolute; right: 16rem; top: -11rem; }

.inner-banner-section{padding: 8rem 0;}



@media (max-width:1199px) {
    .hero-heading .h1 {font-size: 4.4rem;}
    .payment-method {
        width: 35.5rem;
    }
}


@media (max-width:767px) {
    .hero-heading .h1 {font-size: 3.4rem;}
}

.sec-title .h2{ font-size: 5.2rem; margin-bottom: 0; font-weight: bold; }
.sec-title h4{font-size: 2.8rem;color: var(--greenColor);font-weight: 400;margin: 3rem 0;}
.what-sec-text{ max-width: 102.6rem; margin:  auto; font-size: 3.2rem; }



@media (max-width:1199px) {
    .sec-title .h2 {font-size: 4.2rem;}
}

@media (max-width:767px) {
    .sec-title .h2 {font-size: 3.2rem;}
    br {display: none;}
}
.plan-detail-bx{padding: 3.8rem 3rem;border-radius: 1rem;border: 1px solid transparent;transition: 0.3s;height: 100%;}
/* .plan-detail-bx:hover{border-color: var(--blackColor);} */
.light-bg-orange{background: var(--ThemeColorRed)!important;}
.light-bg-purple{background: var(--ThemeColorYellow)!important;}
.light-bg-green{background: var(--greenColor)!important;}
.plan-detail-bx h2{font-size: 3rem;color: var(--whiteColor);margin-bottom: 3rem;text-align: center;text-transform: uppercase;}
.plan-detail-bx ul{margin: 0;padding: 0;margin-bottom: 3rem;}
.plan-detail-bx ul li{list-style: none;padding: 2rem 0;font-size: 1.7rem;font-weight: 400;border-top: 1px solid rgba(255,255,255,0.2);display: flex;align-items: center;min-height: 9.2rem;text-align: left;color: var(--whiteColor);}
.plan-detail-bx ul li::before{content: "";min-width: 2.6rem;height: 1.8rem;background: url(/static/media/check-icon.99c1b3d4.svg) no-repeat;background-size: cover;margin-right: 2rem;display: inline-block;-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
.plan-detail-bx ul li:last-child{border-bottom: 1px solid rgba(255,255,255,0.2);}
.highlighted-plan{box-shadow: 0 1.5rem 3.5rem rgba(0,0,0,0.16);}


.main-plan-bx{position: relative;}
.main-plan-bx ul li{min-height: auto;padding: 1.5rem 0;}
.main-plan-bx h2{margin-bottom: 1rem;}
.main-plan-bx h3{margin-bottom: 1rem;color: var(--whiteColor);font-size: 2rem;}
.main-plan-bx h3 sub{font-size: 3rem;bottom: 0;}
.main-plan-bx h3 strong{font-size: 7rem;}
.main-plan-bx h4{margin-bottom: 3rem;color: var(--whiteColor);font-size: 16px;padding: 1rem 2rem;border-radius: 3rem;background: rgba(255,255,255,0.3);font-weight: 400;display: inline-block;}
.main-plan-bx{padding-bottom: 7rem;}
.main-plan-bx .text-center{position: absolute;left: 0;bottom: 2rem;width: 100%;}
.form-group{margin-bottom: 2rem;position: relative;}
.form-group>label{font-size: 15px;margin-bottom: 5px;}
.show-hide{position: absolute;right: 2rem;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);background: transparent;border: none;cursor: pointer;width: 2.4rem;height: 2.4rem;background: url(/static/media/pass-hide.709144ac.svg) center center no-repeat;transition: 0s;padding: 0;}
.show-hide.active{background: url(/static/media/show-pass.98fadfbf.svg) center center no-repeat;}
.form-control{height: 6rem;background: var(--whiteColor);border-radius: 5px;padding-left: 2rem;font-size: 16px;border: 1px solid #EAEFEB;}
select.form-control{background: var(--whiteColor) url(/static/media/down-arrow.aca88680.svg) no-repeat 95% center;background-size: 1.1rem;padding-left: 1.5rem;padding-right: 3rem;}
select.form-control-sm{background-position-x: 90%;}
.form-control:focus{border-color: var(--darkText);}
.form-control::-webkit-input-placeholder{color: #7E8485;}
.form-control::placeholder{color: #7E8485;}
textarea.form-control{height: 12rem;resize: none;padding-top: 1rem;}
.hasicon{position: relative;}
/* .hasicon .form-control{padding-right: 6rem;} */
.required{position: relative;}
.required::after{content: "*";position: absolute;left: 1.2rem;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);margin-top: -.5rem; color: var(--ThemeColorRed);font-size: 2rem;line-height: 1;}
.input-errors{font-size: 14px;color: var(--ThemeColorRed);margin: 0;margin-top: 2px;}
.msingipack-ftr.with-contact-form{margin-top: 16.7rem;}
.msingipack-ftr .container-fluid{margin-top: 10rem;}
.tab-outer{text-align: center;}
.pricing-tabs.nav-tabs{margin-bottom: 5rem;border: 1px solid #FDECDC;display: inline-flex;border-radius: 1rem;overflow: hidden;}
.pricing-tabs.nav-tabs .nav-link{border: none;margin-bottom: 0;padding: 2rem 3rem;font-size: 2.2rem;color: #c2c2c2;}
.pricing-tabs.nav-tabs .nav-link.active{background: var(--bannerBg);color: var(--darkText);}

.free-trail-bx{background: var(--bannerBg); padding: 4rem;border-radius: 2rem;display: flex;align-items: center;justify-content: space-between;margin-top: 5rem;}
.free-trail-bx h2{font-size: 3rem;font-weight: 700;}
.free-trail-bx p{font-size: 2.4rem;margin-bottom: 0;}
.contact-info a{color: var(--greenColor);}
.msingipack-ftr{background: url(/static/media/ftr-bg.92e9a5ce.svg) center top;background-size: cover;display: inline-block;width: 100%;padding-bottom: 3.5rem;}
.ftr-contact-form{padding: 3.3rem 10.6rem;border-radius: 2rem;background: #EAEFEB;margin-top: -16.7rem;}
.msingipack-ftr-col h2{font-size: 2.2rem;color: var(--whiteColor);font-weight: 500;margin-bottom: 1rem;}
.msingipack-ftr-col ul{padding: 0;margin: 0;}
.msingipack-ftr-col ul li{list-style: none;}
.msingipack-ftr-col ul li + li{margin-top: 1rem;}
.msingipack-ftr-col ul li a{color: var(--whiteColor);font-size: 1.8rem;}
.msingipack-ftr-col ul li a:hover{color: var(--blackColor);}
.msingipack-ftr-col p{display: flex;color: var(--whiteColor);margin-bottom: 2rem;font-size: 1.8rem;}
.msingipack-ftr-col p a{color: var(--whiteColor);}
.msingipack-ftr-col p span{min-width: 10rem;}
.social-links{margin-top: 4rem;}
.social-links img{max-width: 4rem;}
.social-links a{display: inline-block;}
.social-links a + a{margin-left: 2rem;}
.social-links a:hover{-webkit-transform: translateY(-5px);transform: translateY(-5px);}
.copyright{display: flex;justify-content: space-between;align-items: center;position: relative;}
.copyright a img{-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);max-width: 13.6rem;}
.copyright p{font-size: 16px;color: var(--whiteColor);position: absolute;left: 50%;top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
.payment-method{width: 52.5rem;}
.payment-method img { max-width: 29.4rem; background: rgba(255, 255, 255,1); padding: 0.5rem 1rem; border-radius: 1rem; }

/***** What MsingiPACK *****/
.what-is-msingipack { text-align: center; max-width: 120rem; margin: 0 auto; margin-top: 3rem; font-size: 2rem; }
.what-is-msingipack p strong{font-weight: 500;}
.what-is-msingipack p + p{margin-top: 2rem;}
.what-sec-text{ max-width: 102.6rem; margin:  auto; font-size: 3.2rem; }
/***** What MsingiPACK End *****/

.process-section{position: relative;}
.process-section::after{content: "";width: 100%;height: 100%;position: absolute;left: 0;top: 0;background: var(--lightBg);-webkit-clip-path: polygon(0 0, 100% 0, 100% 49%, 0% 100%);clip-path: polygon(0 0, 100% 0, 100% 49%, 0% 100%);}
.process-section .container{position: relative;z-index: 1;}
.process-item .process-text{ margin-top: 2rem; }
.process-item .process-text span{ margin-bottom: 0.5rem; display: block; color: var(--darkText); font-size: 2rem; }
.process-item .process-text h3{ color: var(--darkText); font-size: 2.2rem; }
.process-icon{position: relative;}
.process-icon:after{content: "";width: 4.1rem; height: 2rem;background: url(/static/media/arrow-icon.8df275c9.svg) no-repeat;background-size: cover;position: absolute; right: -2.5rem;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);}
.process-section .col-md-3:last-child .process-icon:after{display: none;}
.process-section .col-md-3:nth-child(2) .process-icon:after{-webkit-transform: translateY(-50%) rotateX(180deg);transform: translateY(-50%) rotateX(180deg);}


.ready-to-start{background: url(/static/media/kid-flower-vector.5e3f96a7.svg) no-repeat right bottom;}


.child-image-bx{background: url(/static/media/children-bg.3b457517.svg) no-repeat center bottom;min-height: 550px;margin-bottom: -20rem;text-align: center;}

.why-choose-points{padding: 0;margin: 0;}
.why-choose-points li{list-style: none;padding-left: 13rem;position: relative;}
.why-choose-points li + li{margin-top: 5rem;}
.why-choose-points li::before{content: attr(count);width: 10rem;height: 7.5rem;background: url(/static/media/cricle-shape.b21973c8.svg) no-repeat;background-size: cover;position: absolute;left: 0;top: 0;display: flex;align-items: center;justify-content: center;color: var(--darkText);font-size: 4.2rem;font-weight: 300;}
.why-choose-points li h2{font-size: 2.6rem;font-weight: 700;color: var(--darkText);margin-bottom: 1rem;}
.why-choose-points li p{font-size: 2rem;color: var(--TextColor);}

.our-plans{background: #f1f6e9;}
/* #content-plans::before {
    content: '';
    display: table;
    height:180px;
    margin-top: -180px;
    visibility: hidden;
    width: 100%;
  } */

  /* :target {
    display: block;    
    position: relative;     
    top: -100px;
    visibility: hidden;
} */
.tab-outer {
    text-align: center;
}

.ContentPlansTabs.nav-tabs {
    margin: 0 -2.2rem;
    border: none;
    display: flex;
    border-radius: 1rem;
}

.ContentPlansTabs.nav-tabs .nav-item {
    flex: 1 1 auto;
    padding: 0 2.2rem;
}

.ContentPlansTabs.nav-tabs .nav-link {
    border: none;
    border-top: 1rem solid transparent;
    margin-bottom: 0;
    padding: 2rem 3rem;
    font-size: 2.2rem;
    color: var(--whiteColor);
    border-radius: 1rem;
    width: 100%;
    transition: 0.3s;
    position: relative;
}

.ContentPlansTabs.nav-tabs .nav-link.active {
    background: var(--bannerBg);
    border-top-color: var(--blackColor);
    box-shadow: 0 1.5rem 3.5rem rgba(0, 0, 0, 0.16) !important;
}

.ContentPlansTabs.nav-tabs .nav-link.active::after {
    content: "";
    width: 3.5rem;
    height: 3rem;
    position: absolute;
    top: calc(100% + 5rem);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.ContentPlansTabs.nav-tabs .nav-link img {
    max-width: 9.8rem;
}

.ContentPlansTabs.nav-tabs .nav-link h2 {
    margin: 2rem 0 1rem 0;
    font-size: 3rem;
    font-weight: 500;
}

.ContentPlansTabs.nav-tabs #ContentPlansTabs-tab-bronze-plans,
.ContentPlansTabs.nav-tabs #ContentPlansTabs-tab-bronze-plans::after {
    background: var(--ThemeColorRed);
}

.ContentPlansTabs.nav-tabs #ContentPlansTabs-tab-silver-plans,
.ContentPlansTabs.nav-tabs #ContentPlansTabs-tab-silver-plans::after {
    background: var(--ThemeColorYellow);
}

.ContentPlansTabs.nav-tabs #ContentPlansTabs-tab-gold-plans,
.ContentPlansTabs.nav-tabs #ContentPlansTabs-tab-gold-plans::after {
    background: var(--greenColor);
}

#ContentPlansTabs-tabpane-bronze-plans.active .contentPlans-bx {
    border-color: var(--ThemeColorRed);
    --greenColor: var(--ThemeColorRed);
}

#ContentPlansTabs-tabpane-silver-plans.active .contentPlans-bx {
    border-color: var(--ThemeColorYellow);
    --greenColor: var(--ThemeColorYellow);
}

#ContentPlansTabs-tabpane-gold-plans.active .contentPlans-bx {
    border-color: var(--greenColor);
}

#ContentPlansTabs-tabpane-bronze-plans.active .contentPlans-bx::after {
    background: var(--ThemeColorRed);
}

#ContentPlansTabs-tabpane-silver-plans.active .contentPlans-bx::after {
    background: var(--ThemeColorYellow);
}

#ContentPlansTabs-tabpane-gold-plans.active .contentPlans-bx::after {
    background: var(--greenColor);
}

.contentPlans-bx {
    margin-top: 8rem;
    padding: 7rem 5rem 3rem 5rem;
    border-radius: 2rem;
    box-shadow: 0 1.5rem 3.5rem rgba(0, 0, 0, 0.16);
    position: relative;
    overflow: hidden;
    border: 1px solid transparent;
}

.contentPlans-bx::after {
    content: attr(offer);
    width: 25rem;
    background: #FF0000;
    color: var(--whiteColor);
    text-align: center;
    padding: .8rem;
    position: absolute;
    left: -8.2rem;
    top: 1.9rem;
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg);
    font-weight: 500;
    font-size: 13px;
}

.contentPlans-col {
    padding: 2rem;
    border: 1px solid #EAEFEB;
    border-radius: 1rem;
    margin-top: 1rem;
}

.contentPlans-col.active {
    background: #fffef9;
    border-color: var(--ThemeColorYellow);
}

/* .contentPlans-col + .contentPlans-col{margin-top: 1rem;} */
.priceBx {
    background: #F5F5F5;
    border-radius: 3rem;
}

.priceBx label {
    flex: 0 0 33.33%;
    padding: 0 1.5rem;
}

.priceBx label input {
    display: none;
    border-radius: 3rem;
}

.priceBx label span {
    padding: 1rem;
    border-radius: 3rem;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-weight: 500;
}

.priceBx label input:checked+span {
    background: var(--greenColor);
    color: var(--whiteColor);
}

.check-ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.check-ul li {
    list-style: none;
    width: 33.333%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.check-ul li::before {
    content: "";
    display: inline-block;
    width: 2rem;
    height: 1.5rem;
    margin-right: 1rem;
    background: url(/static/media/check-icon.99c1b3d4.svg);
    background-size: cover;
    -webkit-filter: brightness(0);
            filter: brightness(0);
    opacity: 0.5;
}

.checkbox2 input {
    display: none;
}

.checkbox2 span {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox2 span i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
    border-radius: 5px;
    border: 1px solid #919191;
}

.checkbox2 span i::after {
    content: "";
    display: inline-block;
    width: 1.3rem;
    height: 1rem;
    background: url(/static/media/check-icon.99c1b3d4.svg);
    background-size: cover;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.checkbox2 input:checked+span i {
    background: var(--greenColor);
}

.radio-btn input {
    display: none;
}

.radio-btn span {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.radio-btn span i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    margin-right: 1rem;
    border: 1px solid #919191;
    position: relative;
}

.radio-btn span i::after {
    content: "";
    display: none;
    width: 1.4rem;
    height: 1.4rem;
    background: var(--greenColor);
    border-radius: 50%;
    position: absolute;
}

.radio-btn input:checked+span i::after {
    display: block;
}

.select-all-btn {
    font-size: 1.8rem;
    color: var(--greenColor);
    border: none;
    box-shadow: none;
    background: transparent;
}

.subscription-total {
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
    align-items: center;
    background: var(--whiteColor);
    padding-bottom: 1rem;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
}

.subscription-total p {
    margin-bottom: 0%;
    margin-right: 6rem;
    font-size: 2rem;
    color: var(--greenColor);
    font-weight: 500;
}

.subscription-total p strong {
    color: var(--blackColor);
}

.grand-total-bx {
    display: flex;
    align-items: center;
}


.subject-price {
    margin-left: 1rem;
    display: inline-block;
    color: var(--greenColor);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
}

.num-of-user {
    display: flex;
    align-items: center;
}

.num-of-user label {
    margin-right: 2rem;
    font-size: 1.8rem;
    line-height: 1.2;
}

.num-of-user label small {
    font-size: 13px;
    display: block;
}

.num-of-user input {
    width: 100px;
    height: 40px;
    border-color: var(--greenColor);
}

.contentplans_num {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.contentplans_num label {
    margin-right: 0;
}

.contentplans_num input {
    margin-left: auto;
    margin-bottom: 8px;
    border-color: #919191;
    width: 18rem;
    border: 1px solid green;
}

.contentplans_num span {
    display: block;
    text-align: right;
}

.contentPlans-col-outer {
    cursor: pointer;
    display: block;
}

.contentPlans-col-outer+.contentPlans-col-outer {
    margin-top: 1rem;
}

.contentPlans-col-outer>input {
    display: none;
}

.contentPlans-col-outer .contentPlans-title i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
    border-radius: 5px;
    border: 1px solid #919191;
}

.contentPlans-col-outer .contentPlans-title i::after {
    content: "";
    display: inline-block;
    width: 1.3rem;
    height: 1rem;
    background: url(/static/media/check-icon.99c1b3d4.svg);
    background-size: cover;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.contentPlans-col-outer input:checked+.contentPlans-col .contentPlans-title i {
    background: var(--greenColor);
}

.contentPlans-col-outer input:checked+.contentPlans-col {
    background: #fffef9;
    border-color: var(--ThemeColorYellow);
}





@media (max-width:767px) {
    .ContentPlansTabs.nav-tabs .nav-item {
        margin-top: 0rem;
        min-width: 240px;
    }

    .ContentPlansTabs.nav-tabs {
        flex-wrap: nowrap;
        margin: 0;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 5rem;
    }

    .ContentPlansTabs.nav-tabs .nav-link.active::after {
        top: calc(100% + 2rem);
    }

    .contentPlans-bx {
        margin-top: 1rem;
        padding: 7rem 2rem 3rem 2rem;
    }

    .contentPlans-col .check-ul {
        flex-direction: column;
    }

    .contentPlans-col .check-ul li {
        width: 100%;
    }

}
.getInTouch-bx{border-radius: 1rem;padding: 3rem;display: block;text-align: center;transition: 0.3s;}
.getInTouch-bx:hover{-webkit-transform: translateY(-5px);transform: translateY(-5px);}
.getInTouch-bx img{max-width: 9.8rem;}
.getInTouch-bx h2{font-size: 3rem;color: var(--whiteColor);margin-top: 2rem;}
.getInTouch-bx p{font-size: 2rem;color: var(--whiteColor);}
.getstarted-section .container .row + .row {
  margin-top: 20rem;
}
.getting-started-img {
  display: inline-block;
  position: relative;
  box-shadow: 0 1.5rem 2.5rem rgba(0, 0, 0, 0.1);
}
.getting-started-img::after {
  content: "";
  width: 64.4rem;
  height: 20rem;
  background: url(/static/media/step-arrow.16ac23fb.svg) no-repeat;
  background-size: cover;
  right: 99.8%;
  top: 100%;
  position: absolute;
}

.getstarted-section
  .container
  .row:nth-child(even)
  .getting-started-img::after {
  right: auto;
  left: 99.8%;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.getstarted-section .container .row:last-child .getting-started-img::after {
  display: none;
}
.filter .btn-sm {
  padding: 0 3rem;
}

@media (max-width: 1199px) {
.getting-started-img::after {
    width: 51.4rem;
    height: 16rem;
}
}

@media (max-width: 991px) {
  .getstarted-section .order-last {
    order: unset !important;
  }
  .getting-started-img::after {
    display: none;
  }
  .getstarted-section .container .row + .row {
    margin-top: 7rem;
  }
  .getting-started-img {
    margin-top: 3rem;
    width: 100%;
    text-align: center;
  }
  .what-sec-text {
    font-size: 2rem;
  }
}

.side-bar-col{position: relative;}
.side-bar-outer{position: -webkit-sticky;position: sticky;top: 10rem;}
.side-bar{padding: 3rem 0;margin: 0;border-right: 1px solid #EAEFEB;height: 100%;}
.side-bar li{display: block;}
.side-bar>li>a{text-transform: uppercase;font-weight: 500;position: relative;}
.side-bar>li.hasSubmenu>a::after{content: "";position: absolute;top: 50%;right: 2rem;width: 1.2rem;height: 1.2rem;-webkit-transform: translateY(-50%);transform: translateY(-50%);background: url(/static/media/down-arrow.aca88680.svg);}
.side-bar li a{color: #6c757d;font-size: 1.8rem;padding: 1rem 0;display: block;}
.side-bar li a.active,
.side-bar li a:hover{color: var(--darkText);}
.side-bar>li>ul{border-left: 1px solid #EAEFEB;margin-left: 1rem;}


.alert-warning{background: var(--bannerBg);}

.DocumentationContent p{font-size: 17px;color: var(--darkText);margin-top: 2rem;}
.DocumentationContent p strong{color: var(--blackColor);}
.DocumentationContent h2{font-weight: 500;margin: 3rem 0 1rem 0;font-size: 4.5rem;color: var(--darkText);padding-top: 3rem;border-top: 1px solid #EAEFEB;}
.DocumentationContent h3{font-weight: 500;margin: 3rem 0 2rem 0;color: var(--darkText);font-size: 2.5rem;}
.DocumentationContent h4{font-weight: 500;color: var(--darkText);font-size: 2rem;margin: 3rem 0 1rem 0;}
.DocumentationContent.leaderboard-tab{padding: 4rem;}
.DocumentationContent .br-none{border: none;}
.DocumentationContent blockquote{    background-color: rgba(255,229,100,0.3); border-left-color: #ffe564; border-left-width: .9rem; border-left-style: solid; padding: 2rem 4.5rem 2rem 2.6rem; margin-bottom: 3rem; margin-top: 2rem;}

.highlight{background: rgb(40, 44, 52);color: #fcffa6;padding: 1.5rem;border-radius: 1rem;margin-top: 2rem;}
.DocumentationContent code { word-break: break-all; -webkit-hyphens: auto; hyphens: auto;     background-color: rgba(0,0,0,0.05); padding: 3px; border-radius: 3px;color: #707070;font-size: 14px;}
.table>:not(caption)>*>*{padding: 1rem;}

.highlight-outer{position: relative;}
.copy-code{position: absolute;right: 1rem;top: 1rem;background: var(--bannerBg);border: none;padding: .7rem;border-radius: 5px;line-height: 0;border: 1px solid transparent;}
.copy-code:hover{border-color: #ffdcb3;}
.copy-code img{width: 2rem;}

.qty-input{width: 9rem;padding: 1rem;height: 4rem;text-align: center;margin: 0 auto;}
.table{border: 1px solid #eef0f8;border-radius: 1rem;}
.order-total{padding: 2rem;border-radius: 1rem; background: #f9f9f9;max-width: 30rem;margin-left: auto;}
.order-total p{margin: 0;margin-top: 1.5rem;display: flex;justify-content: space-between;}
.PageNotFound{text-align: center;}
.PageNotFound img{width: 100rem;}
.PageNotFound h1{font-size: 6rem;margin: 3rem 0;}
.authentication-banner{background: var(--bannerBg);min-height: calc(100vh - 12.9rem);padding: 3rem 0; display: flex;align-items: center;justify-content: center;}
.authentication-bx{background: var(--whiteColor);padding: 5rem;border-radius: 2rem;max-width: 50rem;margin: 0 auto;}
.forgot-link { margin: 0 0 3rem 0; -webkit-display: flex; -moz-display: flex; display: flex; justify-content: space-between; }
.custom-check-block { position: relative; }
.custom-check-block .custom-check-label { font-size: 16px; padding-left: 3.5rem; margin: 0; font-weight: normal; color: #7E8485; cursor: pointer;}
.custom-check-block .custom-check-label:after, 
.custom-check-block .custom-check-label:before { content: ""; position: absolute; border-radius: .5rem; top: .5rem; }
.custom-check-block .custom-check-label:after, 
.custom-check-block .custom-check-label:before{ top: 50%; -webkit-transform: translate(0px, -50%); transform: translate(0px, -50%); }
.custom-check-block .custom-check-label:after { height: 2.5rem; width: 2.5rem; border: 1px solid #a9a9a9; left: 0; }
/* .custom-check-block input:checked ~ .custom-check-label:before { content: "\f00c"; font-family: "Font Awesome 5 Pro"; z-index: 8; left: .5rem; color: var(--whiteColor); } */
.custom-check-block input:checked + .custom-check-label:after { border-color: var(--greenColor); background: var(--greenColor) url(/static/media/check-icon.99c1b3d4.svg) no-repeat center center; background-size: 15px; }
.forgot-link a { color: var(--greenColor); text-decoration: underline;font-size: 16px;}
.forgot-link a:hover{ color: var(--greenColor); }
.create-account{text-align: center;margin-top: 3rem;color: #7E8485;font-size: 16px;margin-bottom: 0;}
.create-account a{color: var(--greenColor);}

.sign-up-authentication{max-width: 89.5rem;}

.dashboard-side-bar {
  padding: 3rem 1rem;
  transition: 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 30rem;
  overflow: auto;
  background: var(--greenColor);
  border-radius: 0;
  z-index: 9999;
}

.dashboard-side-bar nav {
  height: 100%;
  overflow: auto;
}

.dashboard-side-bar ul {
  margin: 0;
  padding: 0;
}

.dashboard-side-bar ul>li {
  list-style: none;
  padding: 0 0;
  position: relative;
  overflow: hidden;
}

.dashboard-side-bar ul>li+li {
  margin-top: 1rem;
}

.dashboard-side-bar ul>li>a,
.dashboard-side-bar ul>li>button {
  color: var(--whiteColor);
  padding: 1.2rem 2rem;
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  border-radius: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  background: transparent;
  border: none;
  width: 100%;
}

/* .dashboard-side-bar ul>li>a img,
.dashboard-side-bar ul>li>button img{width: 2.5rem;margin-right: 2rem;filter: brightness(0) invert(1);} */
.dashboard-side-bar ul>li>a:hover,
.dashboard-side-bar ul>li>button:hover {
  background: #25723a;
}

.dashboard-side-bar ul>li>a.active {
  background: #055219;
  color: var(--whiteColor);
}

.toggle-link::after {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  right: 1.5rem;
  background: url(/static/media/down-arrow.aca88680.svg) no-repeat;
  background-size: cover;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0.8;
}

.dashboard-side-bar ul.submenu {
  padding: 0 2rem 0 3.5rem;
  display: none;
  margin-top: 1rem;
}

.dashboard-side-bar ul.submenu li a {
  color: #8b8b99;
  padding: 1rem 1.5rem;
  display: block;
  font-size: 15px;
  border-radius: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-side-bar ul.submenu li a:hover {
  background: #ececec;
}

.dashboard-side-bar ul.submenu li a.active {
  background: #e1f0ff;
  color: #3699ff;
}

.dashboard-side-bar ul.submenu li+li {
  margin-top: 0.5rem;
}

.dashboard-side-bar ul li a i.icon {
  width: 2rem;
  text-align: center;
  margin-right: 0.5rem;
  color: #3699ff;
}

.dashboard-side-bar ul li .material-icons {
  margin-right: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0);
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #055219;
}

::-webkit-scrollbar-thumb:hover {
  background: #044214;
}

.notifications {
  display: flex;
  align-items: center;
}

.notifications .material-icons {
  font-size: 32px;
}
.mpc-header {
  padding: 15px 15px;
  position: relative;
  z-index: 99;
  background: #f5f6f8;
}

.navbar-brand.dashboard-logo {
  padding: 0;
}

.navbar-brand.dashboard-logo a {
  /* width: 30rem; */
  background: var(--greenColor);
  text-align: left;
  padding: 2rem 0 0.8rem 0;
  margin-bottom: 2rem;
  display: inline-block;
  text-align: center;
  border-radius: 0;
}

.navbar-brand.dashboard-logo img {
  width: 10rem;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.for-mobile {
  display: none;
}

.menu-toggle {
  font-size: 2.5rem;
  background: transparent;
  border: none;
  margin-left: 2rem;
  opacity: 0.7;
  line-height: 0.4;
  position: relative;
  top: 0.5rem;
}

.menu-toggle::after {
  content: "\f0c9";
  font-family: "Font Awesome 5 Pro";
}

.hide-sideBar .menu-toggle::after {
  content: "\f054";
}

.navbar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-user .user-btn {
  border-radius: 30px;
  font-size: 1.3rem;
  border: none;
  display: inline-block;
  padding: 0 1.5rem 0 0;
  display: flex;
  align-items: center;
  background: var(--whiteColor);
  color: var(--darkText);
}

.navbar-user .user-btn div {
  text-align: left;
  margin-left: 1rem;
}

.navbar-user .user-btn strong {
  display: block;
  font-size: 14px;
}

.navbar-user .user-btn span {
  font-size: 14px;
  opacity: 0.5;
}

.navbar-user .user-btn img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 4px;
}

.dropdown-toggle::after {
  display: none;
}

.user-coin {
  background: var(--SecondaryColor);
  color: var(--whiteColor);
  border-radius: 50px;
  padding: 0.4rem 2rem;
  font-size: 1.3rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
}

.user-coin p {
  margin: 0;
}

.navbar-right>a {
  font-size: 2.2rem;
  margin-right: 2rem;
  color: var(--BlackColor);
  position: relative;
}

.navbar-right>a .dot {
  width: 0.5rem;
  height: 0.5rem;
  background: var(--PrimaryColor);
  position: absolute;
  right: 0.2rem;
  top: 0.7rem;
  border-radius: 50%;
}

.navbar-user .dropdown-menu {
  padding: 0;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  width: 182px;
  overflow: hidden;
}

.navbar-user .dropdown-menu a.dropdown-item {
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 1.4rem;
}

.navbar-user .dropdown-menu a.dropdown-item:hover {
  background: #eef0f8;
}

#logo {
  position: fixed;
  pointer-events: none;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.notifications .notifications-count {
  display: inline-block;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #f1556c;
  color: #fff;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.logo-header {
  max-width: 30rem;
  padding: 0 !important;
}

.toggle-btn {
  width: 4rem;
  height: 4rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px #ddd;
  cursor: pointer;
  transition: 0.3s;
  z-index: 9;
}

.toggle-btn:hover {
  background-color: #0b6623;
  color: #fff;
  border-radius: 5px;
}
.mpc-dashboard {
  position: relative;
  margin-left: 30rem;
  transition: 0.3s;
}

.sidebar-sticky .mpc-dashboard {
  margin-left: 10rem;
}

body.sidebar-sticky .dashboard-side-bar:hover {
  width: 30rem;
}

body.sidebar-sticky .dashboard-side-bar:hover + .mpc-dashboard {
  margin-left: 30rem;
}

body.sidebar-sticky .dashboard-side-bar:hover .menu-name {
  display: inline-block;
}

body.sidebar-sticky .dashboard-side-bar:hover ul li > a,
body.sidebar-sticky .dashboard-side-bar:hover ul li > button {
  justify-content: flex-start;
}

body.sidebar-sticky .dashboard-side-bar:hover ul li .material-icons {
  margin-right: 10px;
}

.dashboard-right-content {
  padding: 3rem 3rem 4rem 3rem;
  height: calc(100vh - 80px);
  overflow: auto;
  transition: 0.3s;
  border-top: 1px solid #dfdfdf;
  background: #f5f6f8;
  /* position: relative; */
}

.main-page-heading {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.with-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter {
  display: flex;
  align-items: center;
}

.filter .btn {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter .form-group {
  margin-bottom: 0;
}

.filter .form-group + .form-group {
  margin-left: 1rem;
}

.repeat-white-bx {
  padding: 2rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  background: var(--whiteColor);
  transition: 0.3s;
  box-shadow: 0 0 3rem -6px rgb(0 0 0 / 10%);
}

/* .hover-effect {cursor: pointer;} */
.hover-effect:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.repeat-white-bx > h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.repeat-white-bx > h2::after {
  content: "";
  display: table;
  clear: both;
}

.shadow-none {
  box-shadow: none !important;
}

.half-mid {
  max-width: 90rem;
  margin: 0 auto;
}

.overflow-hidden {
  overflow: hidden;
}

.dashboard-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-info > div h2 {
  text-transform: uppercase;
  color: var(--blackColor);
  font-size: 16px;
  margin-bottom: 2rem;
}

.dashboard-info > div h4 {
  color: var(--blackColor);
  font-size: 3rem;
  font-weight: 700;
}

.dashboard-info .action-btn {
  width: 6rem;
  height: 6rem;
  font-size: 3rem;
}

.dropdown-item.active,
.dropdown-item:active {
  background: var(--greenColor) !important;
}

.filter .form-control {
  height: 4.5rem;
  box-shadow: none !important;
}

.dashboard-info-bx1 {
  background: #f8e1e1;
}

.dashboard-info-bx1 .bg-icon {
  background: #d13a3a;
}

.dashboard-info-bx2 {
  background: #e1f0fb;
}

.dashboard-info-bx2 .bg-icon {
  background: #359de2;
}

.dashboard-info-bx3 {
  background: #ffedd9;
}

.dashboard-info-bx3 .bg-icon {
  background: #fe8700;
}

/* New css */
.bg-icon {
  background-color: rgb(11 102 35);
  border-color: #0b6623 !important;
  width: 7rem;
  height: 7rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.bg-icon i,
.bg-icon span {
  font-size: 3.5rem;
}

.text-right {
  text-align: right;
}

table button[class*="btn"],
table a[class*="btn"],
.btn-theme {
  border: 1px solid !important;
  border-radius: 5rem !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0 !important;
  padding: 7px !important;
  color: #fff;
  min-width: unset !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.btn-theme {
  background-color: #0b6623;
  color: #fff;
  border-color: #0b6623;
}

.btn-theme:hover {
  background-color: #055219;
  border-color: #055219;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-theme a {
  color: #fff;
  display: flex;
}

h3 {
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.repeat-white-bx.hover-effect p {
  margin: 0;
}

/* Sticky sidebar */
body.sidebar-sticky .menu-name {
  display: none;
}

/* body.sidebar-sticky .dashboard-right-content {
    padding-left: 14rem;
} */

body.sidebar-sticky .dashboard-side-bar {
  width: 10rem;
  z-index: 9999;
}

/* body.sidebar-sticky .navbar-brand.dashboard-logo a,
body.sidebar-sticky .logo-header {
    width: 10rem;
    max-width: 10rem;
} */

body.sidebar-sticky .navbar-brand.dashboard-logo a,
.navbar-brand.dashboard-logo a {
  padding: 0 1rem;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.mpc-header .col {
  transition: 0.3s;
}

body.sidebar-sticky .dashboard-side-bar ul li .material-icons {
  margin-right: 0;
}

body.sidebar-sticky .dashboard-side-bar ul li > a,
body.sidebar-sticky .dashboard-side-bar ul li > button {
  justify-content: center;
}

.setting-bx {
  display: flex;
}

.setting-bx .setting-content-bx {
  width: calc(100% - 7rem);
  padding-left: 20px;
}

.badge {
  min-width: 7rem;
  border-radius: 5rem;
}

.badge-warning {
  background: var(--ThemeColorYellow);
}

.badge-primary {
  background: #0d6efd;
}

.badge-success {
  background: var(--LightGreenColor);
}

.badge-danger {
  background: var(--ThemeColorRed);
}

.count-num {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1;
}

.repeat-white-bx.hover-effect p {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.repeat-white-bx.hover-effect p span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.iframe-sec iframe {
  width: 100%;
  /* min-height: 750px; */
  height: calc(100vh - 210px);
}

.iframe-sec.active {
  /* position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f5f4f1;
  z-index: 9999;
  padding-top: max(5vw, 5rem); */
}

.iframe-sec.active iframe {
  /* height: calc(100vh - 5vw);
  min-height: auto; */
  height: 100vh;
}

.fixed-btn {
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 99999;
}

.view-content-page {
  padding-top: 1rem;
}

.footer-bottom {
  background-color: #145219;
  color: #fff;
  padding: 5px;
  position: absolute;
  left: 0rem;
  right: 0;
  bottom: 0;
}

/* body.sidebar-sticky .mpc-header {
    padding-left: 12rem;
} */
body.sidebar-sticky .footer-bottom {
  left: 10rem;
}

.header-free-trial .btn-dark {
  /* background: linear-gradient(to right, rgb(242, 208, 64), rgb(29, 161, 131)); */
  background: #e4d400;
  border: 1px solid #e4d400;
  color: rgb(255, 255, 255);
  transition: 0.5s;
}

.header-free-trial .btn-dark:hover {
  background: transparent;
  color: #e4d400;
}

.mobile-select-Subscribers .btn {
  height: 46px;
}

@media (max-width: 991px) {
  .navbar-user .user-btn div {
    display: none;
  }

  .iframe-sec.active {
    overflow: auto;
    /* padding-top: 60px; */
  }
  .iframe-sec.active iframe {
    /* height: calc(100vh - 60px); */
    height: 100vh;
  }

  .navbar-user .user-btn img {
    margin-right: 0;
  }

  .navbar-user .user-btn {
    padding: 0;
  }

  .repeat-white-bx.hover-effect p {
    /* flex-direction: column; */
    align-items: flex-end;
    font-size: 14px;
    display: flex !important;
  }

  .dashboard-side-bar {
    -webkit-transform: translate3d(-260px, 0, 0);
            transform: translate3d(-260px, 0, 0);
  }

  body.sidebar-sticky .dashboard-side-bar {
    -webkit-transform: unset;
            transform: unset;
    width: 25rem;
    height: calc(100vh - 100px);
  }

  body.sidebar-sticky .menu-name {
    display: block;
    font-size: 12px;
  }

  body.sidebar-sticky .dashboard-side-bar ul li .material-icons {
    margin-right: 10px;
  }

  body.sidebar-sticky .navbar-brand.dashboard-logo a,
  .navbar-brand.dashboard-logo a {
    min-height: 100px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .logo-header {
    max-width: 10rem;
  }

  /* body.sidebar-sticky .dashboard-right-content,
    .dashboard-right-content {
        padding-left: 3rem;
    } */

  .dashboard-right-content {
    height: calc(100vh - 100px);
  }

  /* .mpc-header {
        height: 100px;
    } */
}

@media (max-width: 991px) {
  .dashboard-side-bar {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
  }

  .dashboard-right-content,
  .mpc-header {
    width: 100%;
  }

  body.sidebar-sticky .dashboard-side-bar {
    -webkit-transform: unset;
            transform: unset;
  }

  table th,
  table td {
    white-space: nowrap;
  }

  .mpc-dashboard {
    margin-left: 0;
  }

  body.sidebar-sticky .dashboard-side-bar {
    height: 100%;
  }

  body.sidebar-sticky .dashboard-side-bar ul li > a,
  body.sidebar-sticky .dashboard-side-bar ul li > button {
    justify-content: flex-start;
  }

  .sidebar-sticky .mpc-dashboard {
    margin-left: 25rem;
    width: 100%;
  }

  .logo-header {
    margin: 0 auto;
  }

  body.sidebar-sticky .footer-bottom {
    left: 0;
  }

  .footer-bottom {
    position: initial;
  }

  .navbar-collapse {
    position: absolute;
    right: 0;
    top: 68px;
    background-color: #fce8d1;
    min-width: 30rem;
    min-height: 100vh;
    padding: 2rem;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transition: 0.3s;
    z-index: 4;
  }

  .main-menu-open .navbar-collapse {
    transition: 0.3s;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  .msingipack-rightNav-side .navbar-nav {
    flex-direction: column;
  }

  .navbar-brand img {
    width: 10.4rem;
  }

  .hero-section {
    margin-top: 0rem;
  }
}

@media (max-width: 991px) {
  .navbar-brand img {
    width: 6.4rem;
  }

  .process-icon:after {
    display: none;
  }

  .process-item {
    margin-bottom: 3rem;
  }

  /* .sec-title {
        margin-bottom: 5rem;
    } */
  .ready-to-start {
    padding-bottom: 8rem;
  }

  .plan-detail-bx {
    height: auto;
    margin-bottom: 5rem;
  }

  .sec-title .h2 br {
    display: none;
  }

  .msingipack-ftr-col {
    margin-bottom: 4rem;
  }

  .copyright a img {
    max-width: 8.6rem;
  }

  .copyright {
    flex-direction: column;
    align-items: flex-start;
  }

  .copyright p {
    position: initial;
    -webkit-transform: unset;
            transform: unset;
    margin: 2rem 0;
  }

  .ftr-contact-form {
    padding: 3.3rem 2.6rem;
  }

  .pricing-tabs.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .pricing-tabs.nav-tabs > li {
    width: 33.33%;
  }

  .pricing-tabs.nav-tabs .nav-link {
    width: 100%;
  }

  .iframe-sec iframe {
    height: calc(100vh - 265px);
  }
}

@media (max-width: 767px) {
  .with-filter {
    flex-direction: column;
  }

  .dashboard-right-content.view-content-page .with-filter {
    flex-direction: row;
  }

  .sidebar-iframe select {
    padding: 5px 5px;
    max-width: 230px;
  }

  .dashboard-right-content.view-content-page .with-filter span {
    margin-bottom: 0;
  }

  .filter {
    flex-direction: column;
    width: 100%;
  }

  .filter .btn,
  .filter span {
    width: 100%;
  }

  .filter .btn {
    padding: 8px 30px !important;
  }

  .togg-btn > select {
    right: -18px;
  }

  .dashboard-right-content,
  .mpc-header {
    padding: 2rem;
  }

  .mobile-block {
    display: block !important;
  }

  .mobile-select-Subscribers {
    margin-top: 15px;
  }

  .mobile-select-Subscribers .btn {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .main-page-heading > span {
    margin-bottom: 15px;
  }

  .dashboard-bx-custom {
    padding: 1rem !important;
  }

  .mobile-hide {
    display: none;
  }

  .pagination .page-link {
    padding: 6px 12px;
  }

  .iframe-sec iframe {
    height: calc(100vh - 225px);
  }
}


.accordion-item{border: none;}
.accordion-button{font-size: 2rem;padding: 2rem; border-bottom: 1px solid #EAEFEB!important;}
.accordion-button:not(.collapsed){background: var(--bannerBg);color: var(--darkText);box-shadow: none!important;}
.accordion-button::after{width: 2rem; height: 2rem;background-size: 2rem;-webkit-filter: brightness(0);filter: brightness(0);}
.accordion-body{font-size: 1.8rem;padding: 3rem;border-bottom: 1px solid #EAEFEB;}
.accordion-item{border: none;}
.accordion-button{font-size: 2rem;padding: 2rem; border-bottom: 1px solid #EAEFEB!important;}
.accordion-button:not(.collapsed){background: var(--bannerBg);color: var(--darkText);box-shadow: none!important;}
.accordion-button::after{width: 2rem; height: 2rem;background-size: 2rem;-webkit-filter: brightness(0);filter: brightness(0);}
.accordion-body{font-size: 1.8rem;padding: 3rem;border-bottom: 1px solid #EAEFEB;}
.accordion-item{border: none;}
.accordion-button{font-size: 2rem;padding: 2rem; border-bottom: 1px solid #EAEFEB!important;}
.accordion-button:not(.collapsed){background: var(--bannerBg);color: var(--darkText);box-shadow: none!important;}
.accordion-button::after{width: 2rem; height: 2rem;background-size: 2rem;-webkit-filter: brightness(0);filter: brightness(0);}
.accordion-body{font-size: 1.8rem;padding: 3rem;border-bottom: 1px solid #EAEFEB;}
.accordion-item{border: none;}
.accordion-button{font-size: 2rem;padding: 2rem; border-bottom: 1px solid #EAEFEB!important;}
.accordion-button:not(.collapsed){background: var(--bannerBg);color: var(--darkText);box-shadow: none!important;}
.accordion-button::after{width: 2rem; height: 2rem;background-size: 2rem;-webkit-filter: brightness(0);filter: brightness(0);}
.accordion-body{font-size: 1.8rem;padding: 3rem;border-bottom: 1px solid #EAEFEB;}
.accordion-item{border: none;}
.accordion-button{font-size: 2rem;padding: 2rem; border-bottom: 1px solid #EAEFEB!important;}
.accordion-button:not(.collapsed){background: var(--bannerBg);color: var(--darkText);box-shadow: none!important;}
.accordion-button::after{width: 2rem; height: 2rem;background-size: 2rem;-webkit-filter: brightness(0);filter: brightness(0);}
.accordion-body{font-size: 1.8rem;padding: 3rem;border-bottom: 1px solid #EAEFEB;}
