.mpc-dashboard {
  position: relative;
  margin-left: 30rem;
  transition: 0.3s;
}

.sidebar-sticky .mpc-dashboard {
  margin-left: 10rem;
}

body.sidebar-sticky .dashboard-side-bar:hover {
  width: 30rem;
}

body.sidebar-sticky .dashboard-side-bar:hover + .mpc-dashboard {
  margin-left: 30rem;
}

body.sidebar-sticky .dashboard-side-bar:hover .menu-name {
  display: inline-block;
}

body.sidebar-sticky .dashboard-side-bar:hover ul li > a,
body.sidebar-sticky .dashboard-side-bar:hover ul li > button {
  justify-content: flex-start;
}

body.sidebar-sticky .dashboard-side-bar:hover ul li .material-icons {
  margin-right: 10px;
}

.dashboard-right-content {
  padding: 3rem 3rem 4rem 3rem;
  height: calc(100vh - 80px);
  overflow: auto;
  transition: 0.3s;
  border-top: 1px solid #dfdfdf;
  background: #f5f6f8;
  /* position: relative; */
}

.main-page-heading {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.with-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter {
  display: flex;
  align-items: center;
}

.filter .btn {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter .form-group {
  margin-bottom: 0;
}

.filter .form-group + .form-group {
  margin-left: 1rem;
}

.repeat-white-bx {
  padding: 2rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  background: var(--whiteColor);
  transition: 0.3s;
  box-shadow: 0 0 3rem -6px rgb(0 0 0 / 10%);
}

/* .hover-effect {cursor: pointer;} */
.hover-effect:hover {
  transform: translateY(-5px);
}

.repeat-white-bx > h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.repeat-white-bx > h2::after {
  content: "";
  display: table;
  clear: both;
}

.shadow-none {
  box-shadow: none !important;
}

.half-mid {
  max-width: 90rem;
  margin: 0 auto;
}

.overflow-hidden {
  overflow: hidden;
}

.dashboard-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-info > div h2 {
  text-transform: uppercase;
  color: var(--blackColor);
  font-size: 16px;
  margin-bottom: 2rem;
}

.dashboard-info > div h4 {
  color: var(--blackColor);
  font-size: 3rem;
  font-weight: 700;
}

.dashboard-info .action-btn {
  width: 6rem;
  height: 6rem;
  font-size: 3rem;
}

.dropdown-item.active,
.dropdown-item:active {
  background: var(--greenColor) !important;
}

.filter .form-control {
  height: 4.5rem;
  box-shadow: none !important;
}

.dashboard-info-bx1 {
  background: #f8e1e1;
}

.dashboard-info-bx1 .bg-icon {
  background: #d13a3a;
}

.dashboard-info-bx2 {
  background: #e1f0fb;
}

.dashboard-info-bx2 .bg-icon {
  background: #359de2;
}

.dashboard-info-bx3 {
  background: #ffedd9;
}

.dashboard-info-bx3 .bg-icon {
  background: #fe8700;
}

/* New css */
.bg-icon {
  background-color: rgb(11 102 35);
  border-color: #0b6623 !important;
  width: 7rem;
  height: 7rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.bg-icon i,
.bg-icon span {
  font-size: 3.5rem;
}

.text-right {
  text-align: right;
}

table button[class*="btn"],
table a[class*="btn"],
.btn-theme {
  border: 1px solid !important;
  border-radius: 5rem !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0 !important;
  padding: 7px !important;
  color: #fff;
  min-width: unset !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.btn-theme {
  background-color: #0b6623;
  color: #fff;
  border-color: #0b6623;
}

.btn-theme:hover {
  background-color: #055219;
  border-color: #055219;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-theme a {
  color: #fff;
  display: flex;
}

h3 {
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.repeat-white-bx.hover-effect p {
  margin: 0;
}

/* Sticky sidebar */
body.sidebar-sticky .menu-name {
  display: none;
}

/* body.sidebar-sticky .dashboard-right-content {
    padding-left: 14rem;
} */

body.sidebar-sticky .dashboard-side-bar {
  width: 10rem;
  z-index: 9999;
}

/* body.sidebar-sticky .navbar-brand.dashboard-logo a,
body.sidebar-sticky .logo-header {
    width: 10rem;
    max-width: 10rem;
} */

body.sidebar-sticky .navbar-brand.dashboard-logo a,
.navbar-brand.dashboard-logo a {
  padding: 0 1rem;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.mpc-header .col {
  transition: 0.3s;
}

body.sidebar-sticky .dashboard-side-bar ul li .material-icons {
  margin-right: 0;
}

body.sidebar-sticky .dashboard-side-bar ul li > a,
body.sidebar-sticky .dashboard-side-bar ul li > button {
  justify-content: center;
}

.setting-bx {
  display: flex;
}

.setting-bx .setting-content-bx {
  width: calc(100% - 7rem);
  padding-left: 20px;
}

.badge {
  min-width: 7rem;
  border-radius: 5rem;
}

.badge-warning {
  background: var(--ThemeColorYellow);
}

.badge-primary {
  background: #0d6efd;
}

.badge-success {
  background: var(--LightGreenColor);
}

.badge-danger {
  background: var(--ThemeColorRed);
}

.count-num {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1;
}

.repeat-white-bx.hover-effect p {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.repeat-white-bx.hover-effect p span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.iframe-sec iframe {
  width: 100%;
  /* min-height: 750px; */
  height: calc(100vh - 210px);
}

.iframe-sec.active {
  /* position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f5f4f1;
  z-index: 9999;
  padding-top: max(5vw, 5rem); */
}

.iframe-sec.active iframe {
  /* height: calc(100vh - 5vw);
  min-height: auto; */
  height: 100vh;
}

.fixed-btn {
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 99999;
}

.view-content-page {
  padding-top: 1rem;
}

.footer-bottom {
  background-color: #145219;
  color: #fff;
  padding: 5px;
  position: absolute;
  left: 0rem;
  right: 0;
  bottom: 0;
}

/* body.sidebar-sticky .mpc-header {
    padding-left: 12rem;
} */
body.sidebar-sticky .footer-bottom {
  left: 10rem;
}

.header-free-trial .btn-dark {
  /* background: linear-gradient(to right, rgb(242, 208, 64), rgb(29, 161, 131)); */
  background: #e4d400;
  border: 1px solid #e4d400;
  color: rgb(255, 255, 255);
  transition: 0.5s;
}

.header-free-trial .btn-dark:hover {
  background: transparent;
  color: #e4d400;
}

.mobile-select-Subscribers .btn {
  height: 46px;
}

@media (max-width: 991px) {
  .navbar-user .user-btn div {
    display: none;
  }

  .iframe-sec.active {
    overflow: auto;
    /* padding-top: 60px; */
  }
  .iframe-sec.active iframe {
    /* height: calc(100vh - 60px); */
    height: 100vh;
  }

  .navbar-user .user-btn img {
    margin-right: 0;
  }

  .navbar-user .user-btn {
    padding: 0;
  }

  .repeat-white-bx.hover-effect p {
    /* flex-direction: column; */
    align-items: flex-end;
    font-size: 14px;
    display: flex !important;
  }

  .dashboard-side-bar {
    transform: translate3d(-260px, 0, 0);
  }

  body.sidebar-sticky .dashboard-side-bar {
    transform: unset;
    width: 25rem;
    height: calc(100vh - 100px);
  }

  body.sidebar-sticky .menu-name {
    display: block;
    font-size: 12px;
  }

  body.sidebar-sticky .dashboard-side-bar ul li .material-icons {
    margin-right: 10px;
  }

  body.sidebar-sticky .navbar-brand.dashboard-logo a,
  .navbar-brand.dashboard-logo a {
    min-height: 100px;
    width: fit-content;
  }

  .logo-header {
    max-width: 10rem;
  }

  /* body.sidebar-sticky .dashboard-right-content,
    .dashboard-right-content {
        padding-left: 3rem;
    } */

  .dashboard-right-content {
    height: calc(100vh - 100px);
  }

  /* .mpc-header {
        height: 100px;
    } */
}

@media (max-width: 991px) {
  .dashboard-side-bar {
    transform: translateX(-300px);
  }

  .dashboard-right-content,
  .mpc-header {
    width: 100%;
  }

  body.sidebar-sticky .dashboard-side-bar {
    transform: unset;
  }

  table th,
  table td {
    white-space: nowrap;
  }

  .mpc-dashboard {
    margin-left: 0;
  }

  body.sidebar-sticky .dashboard-side-bar {
    height: 100%;
  }

  body.sidebar-sticky .dashboard-side-bar ul li > a,
  body.sidebar-sticky .dashboard-side-bar ul li > button {
    justify-content: flex-start;
  }

  .sidebar-sticky .mpc-dashboard {
    margin-left: 25rem;
    width: 100%;
  }

  .logo-header {
    margin: 0 auto;
  }

  body.sidebar-sticky .footer-bottom {
    left: 0;
  }

  .footer-bottom {
    position: initial;
  }

  .navbar-collapse {
    position: absolute;
    right: 0;
    top: 68px;
    background-color: #fce8d1;
    min-width: 30rem;
    min-height: 100vh;
    padding: 2rem;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transition: 0.3s;
    z-index: 4;
  }

  .main-menu-open .navbar-collapse {
    transition: 0.3s;
    transform: translateX(0%);
  }

  .msingipack-rightNav-side .navbar-nav {
    flex-direction: column;
  }

  .navbar-brand img {
    width: 10.4rem;
  }

  .hero-section {
    margin-top: 0rem;
  }
}

@media (max-width: 991px) {
  .navbar-brand img {
    width: 6.4rem;
  }

  .process-icon:after {
    display: none;
  }

  .process-item {
    margin-bottom: 3rem;
  }

  /* .sec-title {
        margin-bottom: 5rem;
    } */
  .ready-to-start {
    padding-bottom: 8rem;
  }

  .plan-detail-bx {
    height: auto;
    margin-bottom: 5rem;
  }

  .sec-title .h2 br {
    display: none;
  }

  .msingipack-ftr-col {
    margin-bottom: 4rem;
  }

  .copyright a img {
    max-width: 8.6rem;
  }

  .copyright {
    flex-direction: column;
    align-items: flex-start;
  }

  .copyright p {
    position: initial;
    transform: unset;
    margin: 2rem 0;
  }

  .ftr-contact-form {
    padding: 3.3rem 2.6rem;
  }

  .pricing-tabs.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .pricing-tabs.nav-tabs > li {
    width: 33.33%;
  }

  .pricing-tabs.nav-tabs .nav-link {
    width: 100%;
  }

  .iframe-sec iframe {
    height: calc(100vh - 265px);
  }
}

@media (max-width: 767px) {
  .with-filter {
    flex-direction: column;
  }

  .dashboard-right-content.view-content-page .with-filter {
    flex-direction: row;
  }

  .sidebar-iframe select {
    padding: 5px 5px;
    max-width: 230px;
  }

  .dashboard-right-content.view-content-page .with-filter span {
    margin-bottom: 0;
  }

  .filter {
    flex-direction: column;
    width: 100%;
  }

  .filter .btn,
  .filter span {
    width: 100%;
  }

  .filter .btn {
    padding: 8px 30px !important;
  }

  .togg-btn > select {
    right: -18px;
  }

  .dashboard-right-content,
  .mpc-header {
    padding: 2rem;
  }

  .mobile-block {
    display: block !important;
  }

  .mobile-select-Subscribers {
    margin-top: 15px;
  }

  .mobile-select-Subscribers .btn {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .main-page-heading > span {
    margin-bottom: 15px;
  }

  .dashboard-bx-custom {
    padding: 1rem !important;
  }

  .mobile-hide {
    display: none;
  }

  .pagination .page-link {
    padding: 6px 12px;
  }

  .iframe-sec iframe {
    height: calc(100vh - 225px);
  }
}
