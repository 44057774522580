header.site-header{ padding: 1.5rem 0; -webkit-transition: 0.4s; -moz-transition: 0.4s; -ms-transition: 0.4s; transition: 0.4s; background: var(--bannerBg); }
.home-page header{ padding: 1.5rem 0 0; }
header.sticky, 
.home-page header.sticky{ position: fixed; z-index: 5; background: var(--bannerBg); width: 100%; left: 0; top: 0; padding: 1rem 0; box-shadow: rgb(0 0 0 / 25%) 0px 0px 5px 0px; }
.msingipack-default-navbar{ flex-wrap: nowrap; align-items: flex-start; }
.sticky .msingipack-default-navbar{ align-items: center; }
.msingipack-rightNav-side .navbar-nav { margin-top: 2rem; flex-direction: row; justify-content: flex-end; align-items: center; position: relative; z-index: 99; }
.sticky .msingipack-rightNav-side .navbar-nav{ margin-top: 0; }
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item) { margin: 0 2.5rem; position: relative; }
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item)>a{ color: var(--darkText); font-size: 16px; font-weight: 500; }
.msingipack-rightNav-side .navbar-nav>li:nth-last-child(2)>a{margin-left: 17rem;}
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item)>a:hover,
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item)>a.active{ color: var(--greenColor); }
.msingipack-rightNav-side .navbar-nav>li.btn-item{ margin-left: 2rem; }
.msingipack-rightNav-side .navbar-nav>li.btn-item .btn{ min-width: 120px; }
.navbar-brand img{ position: relative; z-index: 5; -webkit-transition: 0.3s; -moz-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; width: 13.4rem; }
.sticky .navbar-brand img{ width: 7rem; }

.dropdown-custom{background: var(--greenColor);border-radius: 1rem;padding: 1rem;position: absolute;top: calc(100% + 1rem); left: 0; min-width: 13rem;box-shadow: 0 0 1rem rgba(0,0,0,0.16);display: none;z-index: 99;}
.dropdown-custom::after{content: "";width: 0; height: 0; border-left: 7px solid transparent; border-right: 7px solid transparent; border-bottom: 7px solid var(--greenColor);bottom: 100%;position: absolute; left: 10%;}
.dropdown-custom a{display: block;color: var(--bannerBg);padding: 5px 10px;border-radius: 4px;white-space: nowrap;font-size: 14px;}
.dropdown-custom a:hover,
.dropdown-custom a.active{background: var(--bannerBg);color: var(--darkText);}
.dropdown-custom a + a{margin-top: 2px;}

.msingipack-rightNav-side .navbar-nav>li.hasDropDown::after{content: "";position: absolute;width: 100%;height: 30px;background: transparent;left: 0;top: 100%;}
.msingipack-rightNav-side .navbar-nav>li:not(.btn-item):hover .dropdown-custom{display: block;}


.toggle-btn.website-toggle {display: none !important;}


@media (max-width:1199px) {
    .msingipack-rightNav-side .navbar-nav>li:nth-last-child(2)>a {margin-left: 0;}
    .msingipack-rightNav-side .navbar-nav>li:not(.btn-item) {margin: 0 2rem;}
}
@media (max-width:991px) {
    .toggle-btn.website-toggle {display: flex !important;}
    .msingipack-rightNav-side .navbar-nav>li:not(.btn-item) {
        margin-bottom: 2rem;
    }
    .msingipack-rightNav-side .navbar-nav>li:nth-last-child(2)>a {margin-bottom: 2rem;}
    .num-of-user label {
        max-width: 26rem;
    }
    .subscription-total {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .num-of-user {
        width: 100%;
        justify-content: space-between;
    }
    .grand-total-bx {
        width: 100%;
        justify-content: space-between;
        margin-top: 2rem;
    }
    .msingipack-default-navbar {
        align-items: center;
    }
    .getInTouch-bx {margin-bottom: 3rem;}
    
}

@media (max-width:460px) {
    .grand-total-bx, .num-of-user {
        flex-direction: column;
    }
    .num-of-user label {max-width: 100%;text-align: center;margin: 0 0 2rem;}
    .subscription-total p {
        margin: 0 0 2rem !important;
    }
}