.authentication-banner{background: var(--bannerBg);min-height: calc(100vh - 12.9rem);padding: 3rem 0; display: flex;align-items: center;justify-content: center;}
.authentication-bx{background: var(--whiteColor);padding: 5rem;border-radius: 2rem;max-width: 50rem;margin: 0 auto;}
.forgot-link { margin: 0 0 3rem 0; -webkit-display: flex; -moz-display: flex; display: flex; justify-content: space-between; }
.custom-check-block { position: relative; }
.custom-check-block .custom-check-label { font-size: 16px; padding-left: 3.5rem; margin: 0; font-weight: normal; color: #7E8485; cursor: pointer;}
.custom-check-block .custom-check-label:after, 
.custom-check-block .custom-check-label:before { content: ""; position: absolute; border-radius: .5rem; top: .5rem; }
.custom-check-block .custom-check-label:after, 
.custom-check-block .custom-check-label:before{ top: 50%; -webkit-transform: translate(0px, -50%); -moz-transform: translate(0px, -50%); -ms-transform: translate(0px, -50%); transform: translate(0px, -50%); }
.custom-check-block .custom-check-label:after { height: 2.5rem; width: 2.5rem; border: 1px solid #a9a9a9; left: 0; }
/* .custom-check-block input:checked ~ .custom-check-label:before { content: "\f00c"; font-family: "Font Awesome 5 Pro"; z-index: 8; left: .5rem; color: var(--whiteColor); } */
.custom-check-block input:checked + .custom-check-label:after { border-color: var(--greenColor); background: var(--greenColor) url(../../Assets/images/check-icon.svg) no-repeat center center; background-size: 15px; }
.forgot-link a { color: var(--greenColor); text-decoration: underline;font-size: 16px;}
.forgot-link a:hover{ color: var(--greenColor); }
.create-account{text-align: center;margin-top: 3rem;color: #7E8485;font-size: 16px;margin-bottom: 0;}
.create-account a{color: var(--greenColor);}

.sign-up-authentication{max-width: 89.5rem;}