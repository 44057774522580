.mpc-header {
  padding: 15px 15px;
  position: relative;
  z-index: 99;
  background: #f5f6f8;
}

.navbar-brand.dashboard-logo {
  padding: 0;
}

.navbar-brand.dashboard-logo a {
  /* width: 30rem; */
  background: var(--greenColor);
  text-align: left;
  padding: 2rem 0 0.8rem 0;
  margin-bottom: 2rem;
  display: inline-block;
  text-align: center;
  border-radius: 0;
}

.navbar-brand.dashboard-logo img {
  width: 10rem;
  filter: brightness(0) invert(1);
}

.for-mobile {
  display: none;
}

.menu-toggle {
  font-size: 2.5rem;
  background: transparent;
  border: none;
  margin-left: 2rem;
  opacity: 0.7;
  line-height: 0.4;
  position: relative;
  top: 0.5rem;
}

.menu-toggle::after {
  content: "\f0c9";
  font-family: "Font Awesome 5 Pro";
}

.hide-sideBar .menu-toggle::after {
  content: "\f054";
}

.navbar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-user .user-btn {
  border-radius: 30px;
  font-size: 1.3rem;
  border: none;
  display: inline-block;
  padding: 0 1.5rem 0 0;
  display: flex;
  align-items: center;
  background: var(--whiteColor);
  color: var(--darkText);
}

.navbar-user .user-btn div {
  text-align: left;
  margin-left: 1rem;
}

.navbar-user .user-btn strong {
  display: block;
  font-size: 14px;
}

.navbar-user .user-btn span {
  font-size: 14px;
  opacity: 0.5;
}

.navbar-user .user-btn img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 4px;
}

.dropdown-toggle::after {
  display: none;
}

.user-coin {
  background: var(--SecondaryColor);
  color: var(--whiteColor);
  border-radius: 50px;
  padding: 0.4rem 2rem;
  font-size: 1.3rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
}

.user-coin p {
  margin: 0;
}

.navbar-right>a {
  font-size: 2.2rem;
  margin-right: 2rem;
  color: var(--BlackColor);
  position: relative;
}

.navbar-right>a .dot {
  width: 0.5rem;
  height: 0.5rem;
  background: var(--PrimaryColor);
  position: absolute;
  right: 0.2rem;
  top: 0.7rem;
  border-radius: 50%;
}

.navbar-user .dropdown-menu {
  padding: 0;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  width: 182px;
  overflow: hidden;
}

.navbar-user .dropdown-menu a.dropdown-item {
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 1.4rem;
}

.navbar-user .dropdown-menu a.dropdown-item:hover {
  background: #eef0f8;
}

#logo {
  position: fixed;
  pointer-events: none;
  transform: translateX(-100%);
}

.notifications .notifications-count {
  display: inline-block;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #f1556c;
  color: #fff;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.logo-header {
  max-width: 30rem;
  padding: 0 !important;
}

.toggle-btn {
  width: 4rem;
  height: 4rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px #ddd;
  cursor: pointer;
  transition: 0.3s;
  z-index: 9;
}

.toggle-btn:hover {
  background-color: #0b6623;
  color: #fff;
  border-radius: 5px;
}