.hero-section { padding: 0; background-image: url(../../../Assets/images/msingi-hero-bg.svg); background-position: bottom center; background-size: cover; background-repeat: no-repeat; margin-top: -5.5rem; position: relative; z-index: 2; }
.banner-bg{position: absolute;right: 0;top: -13rem;pointer-events: none;}
.banner-btm-bg{position: absolute;left: 0;bottom: 0;width: 100%;pointer-events: none;}
.banner-btm-bg img{width: 100%;}
.hero-heading .h1 { color: var(--darkText); font-size: 6.4rem; font-weight: 500; margin: 0; }
.hero-caption p { font-size: 3rem; color: var(--darkText); margin: 2rem 0; }
.hero-img{ position: relative; }
.hero-wave-clip { position: absolute; right: 16rem; top: -11rem; }

.inner-banner-section{padding: 8rem 0;}



@media (max-width:1199px) {
    .hero-heading .h1 {font-size: 4.4rem;}
    .payment-method {
        width: 35.5rem;
    }
}


@media (max-width:767px) {
    .hero-heading .h1 {font-size: 3.4rem;}
}