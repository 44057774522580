.side-bar-col{position: relative;}
.side-bar-outer{position: sticky;top: 10rem;}
.side-bar{padding: 3rem 0;margin: 0;border-right: 1px solid #EAEFEB;height: 100%;}
.side-bar li{display: block;}
.side-bar>li>a{text-transform: uppercase;font-weight: 500;position: relative;}
.side-bar>li.hasSubmenu>a::after{content: "";position: absolute;top: 50%;right: 2rem;width: 1.2rem;height: 1.2rem;transform: translateY(-50%);background: url(../../Assets/images/down-arrow.svg);}
.side-bar li a{color: #6c757d;font-size: 1.8rem;padding: 1rem 0;display: block;}
.side-bar li a.active,
.side-bar li a:hover{color: var(--darkText);}
.side-bar>li>ul{border-left: 1px solid #EAEFEB;margin-left: 1rem;}


.alert-warning{background: var(--bannerBg);}

.DocumentationContent p{font-size: 17px;color: var(--darkText);margin-top: 2rem;}
.DocumentationContent p strong{color: var(--blackColor);}
.DocumentationContent h2{font-weight: 500;margin: 3rem 0 1rem 0;font-size: 4.5rem;color: var(--darkText);padding-top: 3rem;border-top: 1px solid #EAEFEB;}
.DocumentationContent h3{font-weight: 500;margin: 3rem 0 2rem 0;color: var(--darkText);font-size: 2.5rem;}
.DocumentationContent h4{font-weight: 500;color: var(--darkText);font-size: 2rem;margin: 3rem 0 1rem 0;}
.DocumentationContent.leaderboard-tab{padding: 4rem;}
.DocumentationContent .br-none{border: none;}
.DocumentationContent blockquote{    background-color: rgba(255,229,100,0.3); border-left-color: #ffe564; border-left-width: .9rem; border-left-style: solid; padding: 2rem 4.5rem 2rem 2.6rem; margin-bottom: 3rem; margin-top: 2rem;}

.highlight{background: rgb(40, 44, 52);color: #fcffa6;padding: 1.5rem;border-radius: 1rem;margin-top: 2rem;}
.DocumentationContent code { word-break: break-all; -webkit-hyphens: auto; -ms-hyphens: auto; hyphens: auto;     background-color: rgba(0,0,0,0.05); padding: 3px; border-radius: 3px;color: #707070;font-size: 14px;}
.table>:not(caption)>*>*{padding: 1rem;}

.highlight-outer{position: relative;}
.copy-code{position: absolute;right: 1rem;top: 1rem;background: var(--bannerBg);border: none;padding: .7rem;border-radius: 5px;line-height: 0;border: 1px solid transparent;}
.copy-code:hover{border-color: #ffdcb3;}
.copy-code img{width: 2rem;}