.plan-detail-bx{padding: 3.8rem 3rem;border-radius: 1rem;border: 1px solid transparent;transition: 0.3s;height: 100%;}
/* .plan-detail-bx:hover{border-color: var(--blackColor);} */
.light-bg-orange{background: var(--ThemeColorRed)!important;}
.light-bg-purple{background: var(--ThemeColorYellow)!important;}
.light-bg-green{background: var(--greenColor)!important;}
.plan-detail-bx h2{font-size: 3rem;color: var(--whiteColor);margin-bottom: 3rem;text-align: center;text-transform: uppercase;}
.plan-detail-bx ul{margin: 0;padding: 0;margin-bottom: 3rem;}
.plan-detail-bx ul li{list-style: none;padding: 2rem 0;font-size: 1.7rem;font-weight: 400;border-top: 1px solid rgba(255,255,255,0.2);display: flex;align-items: center;min-height: 9.2rem;text-align: left;color: var(--whiteColor);}
.plan-detail-bx ul li::before{content: "";min-width: 2.6rem;height: 1.8rem;background: url(../../Assets/images/check-icon.svg) no-repeat;background-size: cover;margin-right: 2rem;display: inline-block;filter: brightness(0) invert(1);}
.plan-detail-bx ul li:last-child{border-bottom: 1px solid rgba(255,255,255,0.2);}
.highlighted-plan{box-shadow: 0 1.5rem 3.5rem rgba(0,0,0,0.16);}


.main-plan-bx{position: relative;}
.main-plan-bx ul li{min-height: auto;padding: 1.5rem 0;}
.main-plan-bx h2{margin-bottom: 1rem;}
.main-plan-bx h3{margin-bottom: 1rem;color: var(--whiteColor);font-size: 2rem;}
.main-plan-bx h3 sub{font-size: 3rem;bottom: 0;}
.main-plan-bx h3 strong{font-size: 7rem;}
.main-plan-bx h4{margin-bottom: 3rem;color: var(--whiteColor);font-size: 16px;padding: 1rem 2rem;border-radius: 3rem;background: rgba(255,255,255,0.3);font-weight: 400;display: inline-block;}
.main-plan-bx{padding-bottom: 7rem;}
.main-plan-bx .text-center{position: absolute;left: 0;bottom: 2rem;width: 100%;}