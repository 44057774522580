@media (min-width: 1400px) {
    .container {
        max-width: 1366px;
    }

    .container-fluid {
        max-width: 1644px;
    }

    .mpc-header {
        height: 80px;
    }
}

@media (max-width: 1700px) {
    html {
        font-size: 58%;
    }

    .container-fluid {
        padding: 0 5rem;
    }

    .banner-bg {
        top: -11rem;
    }

    /* .repeat-white-bx.hover-effect p{display:block !important;} */
    .repeat-white-bx .count-num {
        display: block;
        font-size: 20px;
    }
}

@media (max-width: 1400px) {
    html {
        font-size: 50%;
    }

    .child-image-bx {
        min-height: 500px;
    }

    .mpc-header {
        height: 80px;
    }

    .dashboard-right-content {
        height: calc(100vh - 80px) !important;
    }
}



@media (max-width:1199px) {
    .repeat-white-bx.hover-effect p {
        font-size: 12px;
    }

}

@media (max-width: 991px) {
    video {
        width: 100%;
        height: auto;
        margin: 20px 0 0;
    }

    .pad-b-80 {
        padding-bottom: 4rem;
    }

    .pad-t-80 {
        padding-top: 4rem;
    }

    .why-choose-points li {
        padding-left: 8rem;
    }

    .why-choose-points li::before {
        width: 6rem;
        height: 4.5rem;
        font-size: 3rem;
    }

    .container-fluid {
        padding: 0 2rem;
    }

    .what-sec-text {
        font-size: 2rem;
    }

    .sec-title h4 {
        font-size: 1.8rem;
    }

    .dashboard-right-content {
        height: calc(100vh - 80px) !important;
    }
}