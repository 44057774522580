.btn { font-size: 16px; font-weight: 500; padding: 12px 2px; min-width: 17rem; border-radius: 5px; border: 1px solid transparent; }
.btn:hover .white-icon{transition: 0.3s;}
.btn:hover .white-icon{filter: none;}
.btn-green, button.btn-green:focus, a.btn-green:focus { background: var(--greenColor); color:  var(--whiteColor); }
.btn-green:hover{ background: transparent; border-color: var(--greenColor); color:  var(--greenColor); }
.btn-dark, .btn-dark:focus { background: var(--darkText); color:  var(--whiteColor); }
.btn-dark:hover{ background: transparent; border-color: var(--darkText); color: var(--darkText); }
.btn-red, .btn-red:focus { background: var(--ThemeColorRed); color:  var(--whiteColor); }
.btn-red:hover{ background: transparent; border-color: var(--ThemeColorRed); color: var(--ThemeColorRed); }
.btn-white, .btn-white:focus { background: var(--whiteColor); color:  var(--darkText); }
.btn-white:hover{ background: transparent; border-color: var(--whiteColor); color: var(--whiteColor); }
.btn-purple, .btn-purple:focus { background: var(--ThemeColorYellow); color:  var(--darkText); }

.btn-action{border: none;padding: 0;width: 3.5rem;height: 3.5rem;border-radius: 5px;line-height: 0;display: inline-flex;align-items: center;justify-content: center;}
.btn-action img{width: 1.7rem;filter: brightness(0) invert(1);}

.btn-xs{font-size: 14px;min-width: auto;padding: 7px 20px;}
.btn-sm{font-size: 1.6rem;min-width: auto;padding: 1rem 3rem;}
/* select.btn.btn-green:focus {background-color: transparent; color: #000;border-color: #000;} */
.filter select.btn {
    min-width: 15rem;
    padding: 0;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    box-shadow: 0 0 4px rgb(11 102 35 / 50%);
}