.getstarted-section .container .row + .row {
  margin-top: 20rem;
}
.getting-started-img {
  display: inline-block;
  position: relative;
  box-shadow: 0 1.5rem 2.5rem rgba(0, 0, 0, 0.1);
}
.getting-started-img::after {
  content: "";
  width: 64.4rem;
  height: 20rem;
  background: url(../../Assets/images/step-arrow.svg) no-repeat;
  background-size: cover;
  right: 99.8%;
  top: 100%;
  position: absolute;
}

.getstarted-section
  .container
  .row:nth-child(even)
  .getting-started-img::after {
  right: auto;
  left: 99.8%;
  transform: rotateY(180deg);
}
.getstarted-section .container .row:last-child .getting-started-img::after {
  display: none;
}
.filter .btn-sm {
  padding: 0 3rem;
}

@media (max-width: 1199px) {
.getting-started-img::after {
    width: 51.4rem;
    height: 16rem;
}
}

@media (max-width: 991px) {
  .getstarted-section .order-last {
    order: unset !important;
  }
  .getting-started-img::after {
    display: none;
  }
  .getstarted-section .container .row + .row {
    margin-top: 7rem;
  }
  .getting-started-img {
    margin-top: 3rem;
    width: 100%;
    text-align: center;
  }
  .what-sec-text {
    font-size: 2rem;
  }
}
